import React from "react";
import Box from "@mui/material/Box";
import useSociety from "../../../../../hooks/useSociety";
import {OrderItemDTO} from "../../SocietyAdminOrders";
import SecuredImage from "../../../../securedImage/SecuredImage";

interface AdminOrdersDetailsItemImageBlockProps {
    orderItem: OrderItemDTO
    orderId: number
}

const AdminOrdersDetailsItemImageBlock = ({orderItem, orderId}: AdminOrdersDetailsItemImageBlockProps) => {

    const {society} = useSociety();

    return (
        <Box
            mt={"5px"}
            alignItems={"baseline"}
            display="flex"
            justifyContent="center"
            overflow="hidden"
            sx={{
                height: '100px',
            }}
        >
            <SecuredImage
                url={`/societies/${society.id}/orders/${orderId}/orderitems/${orderItem.id}/${orderItem.image}`}/>
        </Box>
    );
}

export default AdminOrdersDetailsItemImageBlock