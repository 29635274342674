import React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useSociety from "../../../../../hooks/useSociety";
import {GalleryImageDTO} from "../../SocietyGallery";
import useDataDelete from "../../../../../hooks/useDataDelete";


interface DeleteMediaModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    galleryImage: GalleryImageDTO,
}

const DeleteMediaModal = ({open, setOpen, galleryImage}: DeleteMediaModalProps) => {
    const {society} = useSociety();

    const {deleteDataPromise} = useDataDelete(`/societies/${society.id}/gallery/${galleryImage?.id}`, true, false);

    const handleDelete = () => {
        deleteDataPromise()
            .then((response) => {
                window.location.reload();
            })
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Typography variant={"secondVariant"} fontSize={{xs: 18, sm: 20, md: 22}}>
                        Supprimer le média
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cette action est irréversible. Voulez-vous vraiment supprimer définitivement ce média ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                    <Button onClick={() => handleDelete()}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DeleteMediaModal