import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert, Grid2} from "@mui/material";
import {ProductDTO} from "../../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import styles from "../../../cartOverlay/cartOverlayItem/CartOverlayItem.module.css";
import useSociety from "../../../../hooks/useSociety";
import IconButton from "@mui/material/IconButton";
import {AddRounded, DeleteOutline, RemoveRounded} from "@mui/icons-material";
import useFormatNumber from "../../../../hooks/useFormatNumber";
import {useCart} from "../../../../hooks/useCart";

export interface CartVerificationItemProps {
    product: ProductDTO,
    quantity: number,
}

const CartVerificationItem = ({product, quantity}: CartVerificationItemProps) => {

    const [imgError, setImgError] = React.useState(false);
    const {society} = useSociety();
    const {updateCartItemQuantity, removeFromCart} = useCart();
    const {formatCentsToPrice} = useFormatNumber();

    return (
        <>
            <Box
                sx={{
                    display: {sm: 'block'},
                    width: '100%',
                }}
            >
                <Grid2 container>
                    <Grid2 size={{xs: 3, sm: 3, md: 2}} alignContent={"center"}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            overflow="hidden"
                            sx={{
                                height: '100px',
                                alignItems: 'center',
                                alignContent: 'center',
                                paddingBottom: '0px',
                                backgroundColor: '#ffffff',
                            }}
                        >
                            <img
                                className={styles.articleImage}
                                src={imgError || !product.productImages  || product.productImages.length === 0? 'missing-image.png' :
                                    `https://looty.ch:8443/api/v1/societies/${society.id}/products/${product.id}/images/${product.productImages[0].id}`}
                                onError={({currentTarget}) => {
                                    setImgError(true);
                                }}
                                alt="society"/>
                        </Box>
                    </Grid2>
                    <Grid2 size={{xs: 9, sm: 9, md: 10}} container>
                        <Grid2 size={{xs: 12, sm: 12, md: 12}} container>
                            <Grid2 size={9} ml={2}>
                                <Typography
                                    textAlign={"left"}
                                    variant={"firstVariant"}
                                    sx={{
                                        fontSize: {xs: 16, sm: 20, md: 22},
                                    }}
                                    ml={"4px"}
                                >
                                    {product.name}
                                </Typography>
                            </Grid2>
                            <Grid2 size={12} textAlign={"left"} alignContent={"center"} container>
                                <Grid2 size={{xs: 12, sm: 9}}>
                                    <IconButton
                                        aria-label="Decrement"
                                        variant="thirdVariant"
                                        sx={{
                                            pl: '0px',
                                            pr: '4px',
                                            ml: 2,
                                        }}
                                        onClick={() => {
                                            quantity > 1 && updateCartItemQuantity(product.id, quantity - 1)
                                        }}
                                    >
                                        <RemoveRounded
                                            sx={{
                                                fontSize: {xs: '30px', sm: '30px'},
                                                padding: '0px',
                                            }}
                                        />
                                    </IconButton>
                                    <Typography
                                        variant={"firstVariant"}
                                        sx={{
                                            fontSize: {xs: 16, sm: 20, md: 22},
                                            display: 'inline-flex'
                                        }}
                                    >
                                        {quantity}
                                    </Typography>
                                    <IconButton
                                        aria-label="Increment"
                                        variant="thirdVariant"
                                        onClick={() => (!product.stock || quantity < product.stock) && updateCartItemQuantity(product.id, quantity + 1)}
                                        sx={{
                                            pl: '2px',
                                        }}
                                    >
                                        <AddRounded
                                            sx={{
                                                fontSize: {xs: '30px', sm: '30px'},
                                                padding: '0px',
                                            }}
                                        />

                                    </IconButton>
                                    <IconButton
                                        aria-label="Remove"
                                        variant="thirdVariant"
                                        onClick={() => removeFromCart(product.id)}
                                    >
                                        <DeleteOutline
                                            sx={{
                                                fontSize: {xs: '25px', sm: '25px'},
                                                padding: '0px',
                                            }}
                                        />

                                    </IconButton>
                                </Grid2>
                                <Grid2
                                    size={{xs: 12, sm: 3}}
                                    textAlign={{xs: "left", sm: "right"}}
                                    alignContent={"center"}
                                    mt={{xs: 2, sm: 0}}
                                >
                                    <Typography
                                        variant={"firstVariant"}
                                        sx={{
                                            fontSize: {xs: 16, sm: 20, md: 22},
                                            display: 'inline-flex',
                                            ml: 2,
                                            pl: '4px',
                                            pr: '16px',
                                        }}
                                    >
                                        {formatCentsToPrice(quantity * product.price)}.-
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={12} textAlign={"left"}>
                                <Typography
                                    color={!product.stockDefined || product.stock > 10 ? 'success' : 'warning'}
                                    fontSize={'12px'}
                                    ml={2}
                                >
                                    {!product.stockDefined ? 'Disponible' : `${product.stock} pièces disponibles`}
                                </Typography>
                            </Grid2>
                            <Grid2>
                                {(product.stockDefined && quantity > product.stock) && (
                                    <Alert
                                        severity="error"
                                        sx={{mt: 4}}
                                    >
                                        Stock insuffisant. Veuillez réduire la quantité.
                                    </Alert>
                                )}
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Box>
        </>
    );
}

export default CartVerificationItem