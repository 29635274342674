import React, {useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {CategoryDTO} from "../../../../context/SocietyProvider";
import useSociety from "../../../../hooks/useSociety";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import CustomTextField from "../../../customMUIComponents/CustomTextField";
import useDataPut from "../../../../hooks/useDataPut";

interface SocietyAdminCategoriesAddModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    parentCategory?: CategoryDTO,
    setParentCategory: (parentCategory: CategoryDTO | undefined) => void,
    category: CategoryDTO,
    isArticleCategory: boolean, // otherwise, gallery media category
}

const SocietyAdminCategoriesEditModal = ({open, setOpen, parentCategory, setParentCategory, category, isArticleCategory}: SocietyAdminCategoriesAddModalProps) => {
    const {society} = useSociety();

    const [title, setTitle] = useState<string>(category.name);

    const {putDataPromise} = useDataPut(`/societies/${society.id}/${isArticleCategory ? 'categories' : 'gallery-categories'}/${category.id}`, {}, true);

    const handleEditCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        putDataPromise(undefined, {productCategoryId: category.id, name: title}).then(() => {
            window.location.reload();
        }).catch((error) => {
            window.location.reload();
        })
    }

    const handleClose = () => {
        setParentCategory(undefined);
        setTitle(category.name);
        setOpen(false);
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    textAlign: "center",
                }}
            >
                <DialogTitle sx={{mt: -5}}>
                    Ajouter un article
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => handleEditCategory(e)}>
                        <Typography
                            id="modal-modal-title"
                            variant="secondVariant"
                            component="h2"
                            sx={{marginBottom: 2}}
                            fontSize={{xs: 22, sm: 24, md: 26}}
                        >
                            {parentCategory ? 'Modifier cette sous-catégorie' : 'Modifier cette catégorie'}
                        </Typography>

                        {parentCategory && (
                            <Typography
                                id="modal-modal-title"
                                variant="secondVariant"
                                component="h2"
                                sx={{marginBottom: 2}}
                                fontSize={{xs: 16, sm: 18, md: 20}}
                            >
                                Cette catégorie est une sous-catégorie de '{parentCategory.name}'
                            </Typography>
                        )}

                        <CustomTextField
                            value={title}
                            label="Titre"
                            mb={4}
                            type="text"
                            required
                            setField={setTitle}
                            variant="outlined"
                        />

                        <Button
                            type="submit"
                            variant="sixthVariant"
                            fullWidth
                        >
                            Modifier cette {parentCategory && 'sous-'}catégorie
                        </Button>
                    </form>

                </DialogContent>
            </Dialog>
        </>
    );
}

export default SocietyAdminCategoriesEditModal