import React from 'react';
import './App.css';
import "./fonts/insignia.ttf"
import Homepage from "./pages/homepage/Homepage";
import {Route, Routes} from "react-router-dom";
import AnotherPageToDelete from "./pages/pageToDelete/AnotherPageToDelete";
import useDomain from "./hooks/useDomain";
import PersistLogin from "./components/persistLogin/PersistLogin";
import RegistrationConfirmation from "./pages/registrationConfirmation/RegistrationConfirmation";
import MainWrapper from "./components/mainWrapper/MainWrapper";
import Register from "./pages/register/Register";
import SocietiesHomepage from "./pages/societiesHomepage/SocietiesHomepage";
import SocietyWrapper from "./components/societyWrapper/SocietyWrapper";
import SocietyHomepage from "./pages/society/homepage/SocietyHomepage";
import SocietyCart from "./pages/society/cart/SocietyCart";
import RequireAuth from "./components/requireAuth/RequireAuth";
import SocietyAdmin from "./pages/society/admin/SocietyAdmin";
import Unauthorized from "./pages/unauthorized/Unauthorized";
import SocietyArticles from "./pages/society/articles/SocietyArticles";
import Societies from "./pages/societies/Societies";
import CreateAccount from "./pages/createAccount/CreateAccount";
import SocietyGallery from "./pages/society/gallery/SocietyGallery";
import ArticleDetailsWrapper from "./pages/society/articles/ArticleDetailsWrapper";
import Conditions from "./pages/conditions/Conditions";

function App() {

    const {subdomain, isProcessing} = useDomain();

    return (
        isProcessing ? <div>Loading</div> :
            <div className="App">
                <Routes>
                    <Route element={<PersistLogin/>}>
                        <Route element={<MainWrapper/>}>
                            {!subdomain ?
                                <Route>
                                    <Route path="/" element={<Homepage/>}/>
                                    <Route path="/info" element={<SocietiesHomepage/>}/>
                                    <Route path="/confirm-registration" element={<RegistrationConfirmation/>}/>
                                    <Route path="/register" element={<Register/>}/>
                                    <Route path="/create-account" element={<CreateAccount/>}/>
                                    <Route path="/societies" element={<Societies/>}/>
                                    <Route path="/conditions" element={<Conditions/>}/>
                                    <Route path="*" element={<AnotherPageToDelete/>}/>
                                </Route>
                                :
                                <Route path="/" element={<SocietyWrapper subdomain={subdomain}/>}>
                                    <Route path="/" element={<SocietyHomepage/>}/>
                                    <Route path="/articles">
                                        <Route path="/articles" element={<SocietyArticles/>}/>
                                        <Route path="/articles/:id" element={<ArticleDetailsWrapper/>}/>
                                        <Route path="/articles/categories/:categoryId" element={<SocietyArticles/>}/>
                                    </Route>
                                    <Route path="/gallery">
                                        <Route path="/gallery" element={<SocietyGallery/>}/>
                                        <Route path="/gallery/categories/:categoryId" element={<SocietyGallery/>}/>
                                    </Route>
                                    <Route path="/cart" element={<SocietyCart/>}/>
                                    <Route element={<RequireAuth/>}>
                                        <Route path="/dashboard" element={<SocietyAdmin/>}/>
                                    </Route>
                                    <Route path="/unauthorized" element={<Unauthorized/>}></Route>
                                    <Route path="*" element={<AnotherPageToDelete/>}/>
                                </Route>
                            }
                        </Route>
                    </Route>
                </Routes>
            </div>
    );
}

export default App;
