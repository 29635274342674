import React, {useEffect, useState} from "react";
import styles from "./SocietyHomepageArticles.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArticleCard from "../../articleCard/ArticleCard";
import {Button, Grid2} from "@mui/material";
import useSociety from "../../../hooks/useSociety";
import AddArticleModal from "../../societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";
import useDataFetch from "../../../hooks/useDataFetch";
import {ProductDTO} from "../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import {useNavigate} from "react-router-dom";

const SocietyHomepageArticles = () => {

    const {society} = useSociety();

    const [open, setOpen] = useState<boolean>(false);

    const {data: articles} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, true, true);

    const [articlesToDisplay, setArticlesToDisplay] = useState<ProductDTO[]>([]);

    const {articlesSection} = useSectionsRefs();

    const navigate = useNavigate();

    useEffect(() => {
        if (articles) {
            const starArticles = articles.filter((article: ProductDTO) => article.star);
            if (starArticles.length > 0) {
                setArticlesToDisplay(starArticles);
            } else {
                setArticlesToDisplay(articles.slice(0,4))
            }
        }
    }, [articles]);

    return (
        (society.secured || (articles && articles.length > 0)) && (
            <>
                <Box
                    ref={articlesSection}
                    sx={{
                        backgroundColor: "tertiary.main",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className={styles.content}>
                        <Typography
                            sx={{
                                color: '#ffffff',
                                fontSize: {xs: 30, sm: 40},
                            }}
                            variant={"firstVariant"}
                        >
                            ARTICLES
                        </Typography>

                        {society.secured && articlesToDisplay && articlesToDisplay.length === 0 && (
                            <Typography
                                variant={"fourthVariant"}
                                fontSize={{xs: 20, sm: 25}}
                            >
                                Vous seul voyez cette section tant que vous n'avez pas ajouté au moins un article.
                            </Typography>
                        )}

                        {articlesToDisplay && articlesToDisplay.length === 1 && (
                            <Grid2 container>
                                <Grid2 size={{xs: 0, sm: 3, md: 4}}></Grid2>
                                <Grid2 container size={{xs: 12, sm: 6, md: 4}} spacing={6} mt={2}>
                                    {articlesToDisplay.map((article: ProductDTO) => (
                                        <Grid2
                                            key={article.name! + article.id}
                                            size={12}
                                        >
                                            <ArticleCard key={article.id} article={article}/>
                                        </Grid2>
                                    ))}
                                </Grid2>
                                <Grid2 size={{xs: 0, sm: 3, md: 4}}></Grid2>
                            </Grid2>
                        )}

                        {articlesToDisplay && articlesToDisplay.length === 2 && (
                            <Grid2 container>
                                <Grid2 size={{xs: 0, sm: 0, md: 2}}></Grid2>
                                <Grid2 container size={{xs: 12, sm: 12, md: 8}} spacing={6} mt={2}>
                                    {articlesToDisplay.map((article: ProductDTO) => (
                                        <Grid2
                                            key={article.name! + article.id}
                                            size={{xs: 12, sm: 6, md: 6}}
                                        >
                                            <ArticleCard key={article.id} article={article}/>
                                        </Grid2>
                                    ))}
                                </Grid2>
                                <Grid2 size={{xs: 0, sm: 0, md: 2}}></Grid2>
                            </Grid2>
                        )}

                        {articlesToDisplay && articlesToDisplay.length > 2 && (
                            <Grid2 container spacing={6} mt={2}>
                                {articlesToDisplay.map((article: ProductDTO) => (
                                    <Grid2
                                        key={article.name! + article.id}
                                        size={{xs: 12, sm: 6, md: 6, lg: 3}}
                                    >
                                        <ArticleCard key={article.id} article={article}/>
                                    </Grid2>
                                ))}
                            </Grid2>
                        )}

                        <Grid2 container>
                            <Grid2 size={12}>
                                {society.secured && (
                                    <Button
                                        sx={{
                                            mt: 4,
                                        }}
                                        variant={"fourthVariant"}
                                        onClick={() => setOpen(true)}
                                    >
                                        Ajouter un article
                                    </Button>
                                )}
                            </Grid2>
                            <Grid2 size={12}>
                                <Button
                                    sx={{
                                        mt: 6,
                                        fontSize: {xs: 16, sm: 18, md: 20},
                                    }}
                                    variant="fourthVariant"
                                    onClick={() => navigate('/articles')}
                                >
                                    Voir tous les articles
                                </Button>
                            </Grid2>
                        </Grid2>

                    </div>
                </Box>
                <AddArticleModal open={open} setOpen={setOpen}/>
            </>
        )
    );
}

export default SocietyHomepageArticles