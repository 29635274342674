import React, {useCallback, useEffect, useRef} from 'react'
import styles from "./EmblaCarousel.module.css";
import {EmblaCarouselType, EmblaEventType, EmblaOptionsType} from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import {NextButton, PrevButton, usePrevNextButtons} from './EmblaCarouselArrowButton'
import {DotButton, useDotButton} from './EmblaCarouselDotButton'

const TWEEN_FACTOR_BASE = 0.84

const numberWithinRange = (number: number, min: number, max: number): number =>
    Math.min(Math.max(number, min), max)

type PropType = {
    options?: EmblaOptionsType,
    areNeighboursVisible?: boolean,
    urls: string[],
    maxSlides?: number
}

const EmblaCarousel: React.FC<PropType> = ({areNeighboursVisible = true, urls, maxSlides = 0, ...props}: PropType) => {
    const {options} = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)
    const tweenFactor = useRef(0)

    const {selectedIndex, scrollSnaps, onDotButtonClick} =
        useDotButton(emblaApi)

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
        tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
    }, [])

    const tweenOpacity = useCallback(
        (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
            const engine = emblaApi.internalEngine()
            const scrollProgress = emblaApi.scrollProgress()
            const slidesInView = emblaApi.slidesInView()
            const isScrollEvent = eventName === 'scroll'

            emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
                let diffToTarget = scrollSnap - scrollProgress
                const slidesInSnap = engine.slideRegistry[snapIndex]

                slidesInSnap.forEach((slideIndex) => {
                    if (isScrollEvent && !slidesInView.includes(slideIndex)) return

                    if (engine.options.loop) {
                        engine.slideLooper.loopPoints.forEach((loopItem) => {
                            const target = loopItem.target()

                            if (slideIndex === loopItem.index && target !== 0) {
                                const sign = Math.sign(target)

                                if (sign === -1) {
                                    diffToTarget = scrollSnap - (1 + scrollProgress)
                                }
                                if (sign === 1) {
                                    diffToTarget = scrollSnap + (1 - scrollProgress)
                                }
                            }
                        })
                    }

                    const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
                    const opacity = numberWithinRange(tweenValue, 0, 1).toString()
                    emblaApi.slideNodes()[slideIndex].style.opacity = opacity
                })
            })
        },
        []
    )

    useEffect(() => {
        if (!emblaApi) return

        setTweenFactor(emblaApi)
        tweenOpacity(emblaApi)
        emblaApi
            .on('reInit', setTweenFactor)
            .on('reInit', tweenOpacity)
            .on('scroll', tweenOpacity)
            .on('slideFocus', tweenOpacity)
    }, [emblaApi, tweenOpacity])

    const buildCompletedEmblaClass = (): string => {
        return `${styles.embla} ${areNeighboursVisible ? styles.embla__neighbors__visible : styles.embla__neighbors__not__visible}`;
    }

    return (
        <div className={buildCompletedEmblaClass()}>
            <div className={styles.embla__viewport} ref={emblaRef}>
                <div className={styles.embla__container}>

                    {urls.slice(0, maxSlides === 0 ? Number.MAX_VALUE : maxSlides)
                        .map((url: string, index: number) => (
                            <div className={styles.embla__slide} key={index}>
                                <img
                                    className={styles.embla__slide__img}
                                    src={url}
                                    alt="illustration image"
                                />
                            </div>
                        ))}

                    {/*<div className={styles.embla__slide} key={99}>*/}
                    {/*    <video*/}
                    {/*        className={styles.embla__slide__img}*/}
                    {/*        controls*/}
                    {/*        autoPlay={false}*/}
                    {/*        muted*/}
                    {/*        loop*/}
                    {/*    >*/}

                    {/*        <source*/}
                    {/*            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4#t=1.0"*/}
                    {/*            type="video/mp4"/>*/}
                    {/*    </video>*/}
                    {/*</div>*/}

                </div>
            </div>

            {urls.length > 1 && (
                <div className={styles.embla__controls}>
                    <div className={styles.embla__buttons}>
                        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled}/>
                        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled}/>
                    </div>

                    <div className={styles.embla__dots}>
                        {scrollSnaps.map((_, index) => (
                            <DotButton
                                key={index}
                                onClick={() => onDotButtonClick(index)}
                                isActive={index === selectedIndex}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default EmblaCarousel
