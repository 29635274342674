import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../customMUIComponents/CustomTextField";
import useDataPost from "../../../../hooks/useDataPost";
import {ServiceDTO} from "../SocietyHomepageServices";
import useSociety from "../../../../hooks/useSociety";


interface AddServiceModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
}

const AddServiceModal = ({open, setOpen}: AddServiceModalProps) => {

    const {society} = useSociety();

    const [serviceTitle, setServiceTitle] = useState<string>('');
    const [serviceDescription, setServiceDescription] = useState<string>('');

    const {postDataPromise} = useDataPost<ServiceDTO>(`/societies/${society.id}/services`, {}, true, false);

    const handleSubmitNewService = () => {
        postDataPromise(undefined, {
            title: serviceTitle,
            description: serviceDescription,
        }).then((response) => {
            window.location.reload();
        })
    }

    return (
        <>
            <form onSubmit={handleSubmitNewService}>
                <Dialog
                    disablePortal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle>
                        <Typography variant={"secondVariant"} fontSize={{xs: 18, sm: 20, md: 22}}>
                            Ajouter un service
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{
                        minWidth: '300px'
                    }}>
                        <CustomTextField
                            value={serviceTitle}
                            label="Titre"
                            mb={4}
                            mt={2}
                            type="text"
                            required
                            setField={setServiceTitle}
                            variant="outlined"
                        />

                        <CustomTextField
                            value={serviceDescription}
                            label="Description"
                            mb={4}
                            type="text"
                            required
                            setField={setServiceDescription}
                            variant="outlined"
                            multiline={true}
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Annuler</Button>
                        <Button type={"submit"}>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </>
    );
}

export default AddServiceModal