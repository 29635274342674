import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useDataDelete from "../../../../../hooks/useDataDelete";
import useSociety from "../../../../../hooks/useSociety";


interface DeleteServiceModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    serviceId: number,
}

const DeleteServiceModal = ({open, setOpen, serviceId}: DeleteServiceModalProps) => {

    const {society} = useSociety();

    const {deleteDataPromise} = useDataDelete(`/societies/${society.id}/services/${serviceId}`, true, false,);

    const handleDelete = () => {
        deleteDataPromise()
            .then((response) => {
                window.location.reload();
            })
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Typography variant={"secondVariant"} fontSize={{xs: 18, sm: 20, md: 22}}>
                        Supprimer ce service
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{
                    minWidth: '300px'
                }}>
                    <Typography
                        variant={"secondVariant"}
                    >
                        Es-tu sûr de vouloir supprimer ce service ? Cette action est irréversible.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                    <Button onClick={handleDelete}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DeleteServiceModal