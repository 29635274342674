import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../customMUIComponents/CustomTextField";
import useDataPost from "../../../../hooks/useDataPost";
import {CategoryDTO, SocietyDTO} from "../../../../context/SocietyProvider";
import useSociety from "../../../../hooks/useSociety";
import IconButton from "@mui/material/IconButton";
import {DeleteOutline, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {Dialog, DialogContent, Grid2, ListItemIcon, MenuItem} from "@mui/material";
import {ProductDTO, ProductImageDTO} from "../societyAdminArticlesList/SocietyAdminArticlesList";
import useFormatNumber from "../../../../hooks/useFormatNumber";
import UseDataPut from "../../../../hooks/useDataPut";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DeleteArticleModal from "../deleteArticleModal/DeleteArticleModal";
import {getListAllCategories} from "../../../../pages/society/articles/articlesUtils";
import Close from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styles from "./AddArticleModal.module.css"

interface ProductImageForListDTO extends ProductImageDTO {
    isNew: boolean;
    file?: File;
}

interface AddArticleModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    product?: ProductDTO,
}

const AddArticleModal = ({open, setOpen, product}: AddArticleModalProps) => {
    const {society} = useSociety();

    const allCategories = getListAllCategories(society.categories!);

    const {formatCentsToPrice} = useFormatNumber();

    const [title, setTitle] = useState<string>(product?.name || '');
    const [description, setDescription] = useState<string>(product?.description || '');
    const [price, setPrice] = useState<string>((product?.price && formatCentsToPrice(product.price)) || '');
    const [isStockDefined, setIsStockDefined] = useState<boolean | undefined>(product ? product.stockDefined : undefined);
    const [stock, setStock] = useState<string>((product?.stock && product.stock.toString()) || '');
    const [productCategory, setProductCategory] = useState<number>(product?.category ? product.category.id : -1);

    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const [productImages, setProductImages] = useState<ProductImageForListDTO[]>([]);

    const handleSetPrice = (value: string) => {
        const dec = value.indexOf(".");
        const tooLong = value.length > dec + 3;
        const invalidNum = isNaN(parseFloat(value));

        if ((dec >= 0 && tooLong) || invalidNum) {
            setPrice(value.slice(0, -1));
            return;
        }
        setPrice(value);
    }

    const {postData} = useDataPost<SocietyDTO>(`/societies/${society.id}/products`, {}, true, false, true);
    const {putData} = UseDataPut<SocietyDTO>(`/societies/${society.id}/products/${product?.id}`, {}, true, false, true);

    const handleClose = () => {
        setOpen(false);
    }

    const addOrModifyProduct = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData: any = new FormData();
        formData.append('name', title);
        formData.append('description', description);
        formData.append('price', Number(price) * 100);
        formData.append('stockDefined', isStockDefined);
        if (isStockDefined) {
            formData.append('stock', stock);
        }

        productImages.filter((productImage) => productImage.isNew)
            .forEach((productImage: ProductImageForListDTO) => {
                formData.append('additionalImagesOrder', productImage.imageOrder)
                formData.append('additionalImages', productImage.file);
            })

        let keptImagesWithOrder = {};
        productImages.filter((productImage) => !productImage.isNew)
            .forEach((productImage: ProductImageForListDTO) => {
                keptImagesWithOrder = {...keptImagesWithOrder, [productImage.id]: productImage.imageOrder}
            })

        formData.append('keptImagesWithOrder', JSON.stringify(keptImagesWithOrder));

        formData.append('visible', true);
        productCategory > 0 && formData.append('categoryId', productCategory);

        if (product) {
            await putData(undefined, formData);
        } else {
            await postData(undefined, formData);
        }
        window.location.reload();
    };

    const swapProductImage = (productImage: ProductImageForListDTO, goesDown: boolean) => {
        const indexProductImage = productImages.indexOf(productImage);

        if (indexProductImage === -1) {
            return;
        }

        if (indexProductImage === productImages.length - 1 && goesDown) {
            return;
        }

        if (indexProductImage === 0 && !goesDown) {
            return;
        }

        const indexPreviousProductImage = indexProductImage + (goesDown ? 1 : -1);

        setProductImages((prevProductImages) => {
            const newProductImages = prevProductImages.map((item) => ({...item}))

            const otherProductImage = {...newProductImages[indexPreviousProductImage]};
            const toDownProductImage = {...newProductImages[indexProductImage]};

            const otherProductImageOrder = otherProductImage.imageOrder;
            const toDownProductImageOrder = toDownProductImage.imageOrder;

            newProductImages[indexPreviousProductImage] = {...toDownProductImage, imageOrder: otherProductImageOrder};
            newProductImages[indexProductImage] = {...otherProductImage, imageOrder: toDownProductImageOrder};

            return newProductImages
        })
    }

    const handleUploadImage = (file: File) => {
        setProductImages((prevProductImages: ProductImageForListDTO[]) => {
            let lastOrder = 0;
            if (prevProductImages.length > 0) {
                lastOrder = prevProductImages[prevProductImages.length - 1].imageOrder;
            }
            return [...prevProductImages, {
                id: Math.floor(Math.random() * 10000000),
                imageOrder: lastOrder + 1,
                imageName: file.name,
                isNew: true,
                file: file,
            }
            ];
        })
    }

    const handleDeleteImage = (productImage: ProductImageForListDTO) => {
        setProductImages((prevProductImages) => {
            const indexOfElementToDelete = prevProductImages.indexOf(productImage);

            if (indexOfElementToDelete === -1) {
                return prevProductImages;
            }

            const copy = [...prevProductImages];

            copy.splice(indexOfElementToDelete, 1);

            return copy;
        })
    }

    useEffect(() => {
        if (product) {
            setTitle(product.name);
            setDescription(product.description);
            setPrice(formatCentsToPrice(product.price));
            setIsStockDefined(product.stockDefined);
            setStock(product.stock.toString());
            product.category && setProductCategory(product.category.id);
            setProductImages(product.productImages.map((productImage) => ({...productImage, isNew: false})));
        }
    }, [open]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    textAlign: "center",
                }}
            >
                <DialogContent sx={{
                    pr: {xs: '10px', sm: '24px'},
                    pl: {xs: '10px', sm: '24px'},
                }}>
                    <form onSubmit={(e) => addOrModifyProduct(e)}>
                        <Grid2 container sx={{mb: 2}}>
                            <Grid2 size={1}>

                            </Grid2>
                            <Grid2 size={10}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="secondVariant"
                                    component="h2"
                                    fontSize={{xs: 22, sm: 24, md: 26}}
                                >
                                    {product ? 'Modifier un article' : 'Ajouter un article'}
                                </Typography>
                            </Grid2>
                            <Grid2 size={1}>
                                <IconButton
                                    aria-label="Close"
                                    variant="firstVariant"
                                    onClick={handleClose}
                                >
                                    <Close/>
                                </IconButton>
                            </Grid2>
                        </Grid2>

                        <CustomTextField
                            value={title}
                            label="Titre"
                            mb={4}
                            type="text"
                            required
                            setField={setTitle}
                            variant="outlined"
                        />

                        <CustomTextField
                            value={description}
                            label="Description"
                            mb={4}
                            type="text"
                            required
                            setField={setDescription}
                            variant="outlined"
                            multiline={true}
                            rows={4}
                        />

                        <CustomTextField
                            value={price}
                            label="Prix"
                            mb={4}
                            type="number"
                            step={"0.01"}
                            min={0.01}
                            required
                            setField={handleSetPrice}
                            variant="outlined"
                            endAdornment={"CHF"}
                        />

                        <CustomTextField
                            variant={"outlined"}
                            value={isStockDefined === undefined ? "" : isStockDefined ? "yes" : "no"}
                            label={"Définir un stock"}
                            setField={(fieldValue) => setIsStockDefined(fieldValue === "yes")}
                            select
                            selectOptions={[
                                <MenuItem key="yes" value={'yes'}>Oui</MenuItem>,
                                <MenuItem key="no" value={'no'}>Non</MenuItem>
                            ]}
                            mb={4}
                            textAlign={"left"}
                            required
                        />

                        {isStockDefined && (
                            <CustomTextField
                                value={stock}
                                label="Nombre de pièces en stock"
                                mb={4}
                                type="number"
                                step={"1"}
                                min={0}
                                required
                                setField={setStock}
                                variant="outlined"
                            />
                        )}

                        <CustomTextField
                            variant={"outlined"}
                            value={productCategory}
                            label={"Modifier la catégorie"}
                            setField={setProductCategory}
                            select
                            selectOptions={
                                [
                                    <MenuItem key={'uncategorized'} value={-1}>{'Non catégorisé'}</MenuItem>,
                                    ...allCategories.map((category: CategoryDTO) => {
                                        return (<MenuItem key={category.name}
                                                          value={category.id}>{category.name}</MenuItem>)
                                    })
                                ]
                            }
                            mb={4}
                            textAlign={"left"}
                            required
                        />

                        <Typography
                            variant={"firstVariant"}
                            textAlign={"left"}
                        >
                            Images d'illustration
                        </Typography>

                        <List dense sx={{bgcolor: 'background.paper'}}>
                            {productImages.sort((productImageA, productImageB) => productImageA.imageOrder - productImageB.imageOrder)
                                .map((productImage) => {
                                    return (
                                        <ListItem
                                            disableGutters={true}
                                            sx={{mb: 2}}
                                            key={productImage.id}
                                            secondaryAction={
                                                <Grid2 container>
                                                    <Grid2 size={4}>
                                                        <IconButton
                                                            aria-label="Remove"
                                                            variant="thirdVariant"
                                                            onClick={() => {
                                                                swapProductImage(productImage, false);
                                                            }}
                                                        >
                                                            <KeyboardArrowUp
                                                                sx={{
                                                                    fontSize: {xs: '25px', sm: '25px'},
                                                                    padding: '0px',
                                                                }}
                                                            />

                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2 size={4}>
                                                        <IconButton
                                                            aria-label="Remove"
                                                            variant="thirdVariant"
                                                            onClick={() => {
                                                                swapProductImage(productImage, true);
                                                            }}
                                                        >
                                                            <KeyboardArrowDown
                                                                sx={{
                                                                    fontSize: {xs: '25px', sm: '25px'},
                                                                    padding: '0px',
                                                                }}
                                                            />

                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2 size={4}>
                                                        <IconButton
                                                            aria-label="Remove"
                                                            variant="thirdVariant"
                                                            onClick={() => {
                                                                handleDeleteImage(productImage);
                                                            }}
                                                        >
                                                            <DeleteOutline
                                                                sx={{
                                                                    fontSize: {xs: '25px', sm: '25px'},
                                                                    padding: '0px',
                                                                }}
                                                            />

                                                        </IconButton>


                                                    </Grid2>
                                                </Grid2>

                                            }
                                            disablePadding
                                        >
                                            <ListItemButton sx={{pl: '0px'}}>
                                                <ListItemIcon>
                                                    <Box
                                                        maxHeight={'80px'}
                                                        maxWidth={'110px'}
                                                    >
                                                        <img
                                                            className={styles.illustration_image}
                                                            src={
                                                                productImage.isNew ? productImage.file && URL.createObjectURL(productImage.file) :
                                                                    `https://looty.ch:8443/api/v1/societies/${society.id}/products/${product?.id}/images/${productImage.id}`
                                                            }
                                                        />
                                                    </Box>
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                        </List>

                        <input
                            accept="image/*, android/force-camera-workaround"
                            capture
                            style={{display: 'none'}}
                            id="raised-button-file"
                            type="file"
                            onChange={(event) => {
                                if (event.target.files && event.target.files.length > 0) {
                                    if (event.target.files[0].size > 10485760) {
                                        alert('Fichier trop volumineux. Max 10Mb.')
                                    } else {
                                        handleUploadImage(event.target.files[0]);
                                    }
                                }
                            }}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="secondVariant" component="span">
                                Ajouter une image
                            </Button>
                        </label>

                        <Button
                            type="submit"
                            variant="sixthVariant"
                            fullWidth
                            sx={{
                                mt: 2
                            }}
                        >
                            {product ? 'Valider les modifications' : 'Ajouter l\'article'}
                        </Button>
                    </form>

                    {product && (
                        <>
                            <Divider sx={{mt: 2, mb: 2}}/>

                            <Button
                                variant={"fifthVariant"}
                                fullWidth
                                onClick={() => setOpenDeleteModal(true)}
                            >
                                Supprimer définitivement
                            </Button>
                        </>
                    )}

                </DialogContent>
            </Dialog>
            {product && (
                <DeleteArticleModal open={openDeleteModal} setOpen={setOpenDeleteModal} product={product}/>
            )}
        </>
    );
}

export default AddArticleModal