import {createContext, Dispatch, ReactNode, SetStateAction, useState} from "react";
import {createTheme, Theme, ThemeProvider} from "@mui/material";

declare module '@mui/material/styles' {
    interface Components {
        [key: string]: any
    }

    interface Palette {
        tertiary: PaletteOptions['primary'];
        quaternary: PaletteOptions['primary'];
        quintenary: PaletteOptions['primary'];
        senary: PaletteOptions['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
        quaternary: PaletteOptions['primary'];
        quintenary: PaletteOptions['primary'];
        senary: PaletteOptions['primary'];
    }

    interface PaletteColorOptions {
        main: string
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        firstVariant: true;
        secondVariant: true;
        thirdVariant: true;
        fourthVariant: true;
        fifthVariant: true;
        sixthVariant: true;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        firstVariant: true;
        secondVariant: true;
        thirdVariant: true;
        fourthVariant: true;
    }
}

declare module "@mui/material/Link" {
    interface LinkPropsVariantOverrides {
        firstVariant: true;
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonOwnProps {
        variant?: "firstVariant" | "secondVariant" | "thirdVariant";
    }
}

declare module "@mui/material/TextField" {
    interface TextfieldPropsVariantOverrides {
        basic: true;
    }
}

const theme1 = createTheme({
    palette: {
        primary: {
            main: '#543B26',
        },
        secondary: {
            main: '#B0754D',
        },
        tertiary: {
            main: '#D6B59C',
        },
        quaternary: {
            main: '#8C705E'
        },
        quintenary: {
            main: '#FFFFFF'
        },
        senary: {
            main: '#CCA678'
        },
    },
});

const theme2 = createTheme({
    palette: {
        primary: {
            main: '#011F4B',
        },
        secondary: {
            main: '#354476',
        },
        tertiary: {
            main: '#C0CAFF',
        },
        quaternary: {
            main: '#626EA4',
        },
        quintenary: {
            main: '#FFFFFF'
        },
        senary: {
            main: '#909BD4'
        },
    },
});

const theme3 = createTheme({
    palette: {
        primary: {
            main: '#234D20',
        },
        secondary: {
            main: '#457040',
        },
        tertiary: {
            main: '#B4E3AC',
        },
        quaternary: {
            main: '#699562',
        },
        quintenary: {
            main: '#FFFFFF'
        },
        senary: {
            main: '#8EBB86'
        },
    },
});

const customCreateTheme = (passedTheme: Theme) => {
    return createTheme({
        components: {
            MuiButton: {
                variants: [
                    {
                        props: {variant: "firstVariant"},
                        // set the styles for this variant
                        style: {
                            color: "#ffffff",
                            backgroundColor: passedTheme.palette.secondary.main,
                            textTransform: "none"
                        },
                    },
                    {
                        props: {variant: "secondVariant"},
                        // set the styles for this variant
                        style: {
                            color: "#ffffff",
                            backgroundColor: passedTheme.palette.quaternary!.main,
                            textTransform: "none"
                        },
                    },
                    {
                        props: {variant: "thirdVariant"},
                        // set the styles for this variant
                        style: {
                            color: "#ffffff",
                            backgroundColor: passedTheme.palette.tertiary!.main,
                            textTransform: "none"
                        },
                    },
                    {
                        props: {variant: "fourthVariant"},
                        // set the styles for this variant
                        style: {
                            color: "#ffffff",
                            backgroundColor: passedTheme.palette.primary!.main,
                            textTransform: "none"
                        },
                    },
                    {
                        props: {variant: "fifthVariant"},
                        // set the styles for this variant
                        style: {
                            color: "#ffffff",
                            backgroundColor: passedTheme.palette.error.main,
                            textTransform: "none"
                        },
                    },
                    {
                        props: {variant: "sixthVariant"},
                        // set the styles for this variant
                        style: {
                            color: "#ffffff",
                            backgroundColor: passedTheme.palette.success.main,
                            textTransform: "none"
                        },
                    },
                ]
            },
            MuiTextField: {
                // styleOverrides: {
                //     root: {
                //         backgroundColor: "#ffffff",
                //         color: "#000000",
                //         "& label.Mui-focused": {
                //             color: passedTheme.palette.secondary.main
                //         },
                //         // focused color for input with variant='standard'
                //         "& .MuiInput-underline:after": {
                //             borderBottomColor: passedTheme.palette.secondary.main
                //         },
                //         // focused color for input with variant='filled'
                //         "& .MuiFilledInput-underline:after": {
                //             borderBottomColor: passedTheme.palette.secondary.main
                //         },
                //         // focused color for input with variant='outlined'
                //         "& .MuiOutlinedInput-root": {
                //             "&.Mui-focused fieldset": {
                //                 borderColor: passedTheme.palette.secondary.main
                //             },
                //             "& .MuiOutlinedInput-notchedOutline": {
                //                 borderColor: passedTheme.palette.primary.main,
                //                 borderWidth: "1px",
                //             },
                //         },
                //     }
                // }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#ffffff"
                    }
                }
            },
            MuiTypography: {
                variants: [
                    {
                        props: {variant: "firstVariant"},
                        style: {
                            color: "#000000",
                            display: 'block',
                            fontFamily: 'insignia',
                        },
                    },
                    {
                        props: {variant: "secondVariant"},
                        style: {
                            color: passedTheme.palette.primary.main,
                            display: 'block',
                            fontFamily: 'insignia',
                        },
                    },
                    {
                        props: {variant: "thirdVariant"},
                        style: {
                            color: passedTheme.palette.primary.main,
                            display: 'block',
                            fontFamily: 'insignia',
                        },
                    },
                    {
                        props: {variant: "fourthVariant"},
                        style: {
                            color: "#ffffff",
                            display: 'block',
                            fontFamily: 'insignia',
                        },
                    },
                ],
            },
            MuiLink: {
                variants: [
                    {
                        props: {variant: "firstVariant"},
                        style: {
                            color: passedTheme.palette.primary.main,
                            display: 'block',
                            fontFamily: 'insignia',
                        },
                    },
                ]
            },
            MuiIconButton: {
                variants: [
                    {
                        props: {variant: "firstVariant"},
                        style: {
                            color: passedTheme.palette.primary.main,
                            backgroundColor: "#ffffff"
                        },
                    },
                    {
                        props: {variant: "secondVariant"},
                        style: {
                            color: passedTheme.palette.quintenary!.main,
                        },
                    },
                    {
                        props: {variant: "thirdVariant"},
                        style: {
                            color: "#000000",
                        },
                    },
                ]
            },
        },
    }, passedTheme);
}

const themesMap = new Map<string, Theme>();
themesMap.set('CLASSIC', customCreateTheme(theme1));
themesMap.set('BLUE', customCreateTheme(theme2));
themesMap.set('GREEN', customCreateTheme(theme3));


const getThemeByName = (theme: string) => {
    return themesMap.get(theme);
}

interface CustomThemeContextInterface {
    themeName: string
    setThemeName: Dispatch<SetStateAction<string>>
}

const CustomThemeContext = createContext<CustomThemeContextInterface>({
    themeName: 'firstOne',
    setThemeName: () => {
    }
});

interface CustomThemeProviderProps {
    children: ReactNode
}

export const CustomThemeProvider = ({children}: CustomThemeProviderProps) => {
    const [themeName, setThemeName] = useState<string>('CLASSIC');

    const theme = getThemeByName(themeName)!;

    return (
        <CustomThemeContext.Provider value={{
            themeName,
            setThemeName
        }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </CustomThemeContext.Provider>
    )
}

export default CustomThemeContext;