import {CategoryDTO, SocietyDTO} from "../../../context/SocietyProvider";

export const getPathToSubcategory = (rootCategory: CategoryDTO, subcategoryId: number): CategoryDTO[] | null => {
    function traverse(
        category: CategoryDTO,
        currentPath: CategoryDTO[]
    ): CategoryDTO[] | null {
        if (category.id === subcategoryId) {
            return [...currentPath, category]; // Found the subcategory
        }

        if (category.subcategories) {
            for (const subcategory of category.subcategories) {
                const foundPath = traverse(subcategory, [...currentPath, category]);
                if (foundPath) {
                    return foundPath; // Path found in a subcategory
                }
            }
        }

        return null; // Subcategory not found in this branch
    }

    return traverse(rootCategory, []);
}

export const getPathToSubcategoryEntry = (topLevelCategories: CategoryDTO[], subcategoryId: number): CategoryDTO[] | null => {
    if (topLevelCategories) {
        for (let i = 0; i < topLevelCategories?.length; i++) {
            const result = getPathToSubcategory(topLevelCategories[i], subcategoryId);
            if (result) {
                return result;
            }
        }
    }
    return null;
}

export const mapCategoriesPathToIds = (categories: CategoryDTO[], categoryId: number): number[] => {
    const pathToSubcategoryEntry = getPathToSubcategoryEntry(categories, categoryId);
    if (!pathToSubcategoryEntry) {
        return [];
    }
    return pathToSubcategoryEntry.map((category) => category.id);
}

export const getListAllCategories = (categories: CategoryDTO[]): CategoryDTO[] => {
    const allCategories: CategoryDTO[] = [];
    categories.forEach((category: CategoryDTO) => {
        addCategoryToList(category, allCategories);
    })

    return allCategories;
}

const addCategoryToList = (category: CategoryDTO, list: CategoryDTO[]) => {
    list.push(category);
    category.subcategories.forEach((category: CategoryDTO) => {
        addCategoryToList(category, list);
    })
}