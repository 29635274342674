import React, {useEffect, useState} from "react";
import useDataFetch from "../../hooks/useDataFetch";
import styles from "./SecuredImage.module.css"

interface SecuredImageProps {
    url: string
}

const SecuredImage = ({url}: SecuredImageProps) => {

    const [imgError, setImgError] = useState<boolean>(false);

    const {data} = useDataFetch<any>(url, true, true, true);

    const [src, setSrc] = useState<string>('');

    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (data) {
            const imageObjectURL = URL.createObjectURL(data);
            setSrc(imageObjectURL);
            setLoaded(true);
        }
    }, [data]);

    return (
        loaded ? (
            <img
                className={styles.securedImage}
                src={imgError ? '/missing-image.png' : src}
                onError={({currentTarget}) => {
                    setImgError(true);
                }}
                alt="society"/>
        ) : (
            <img className={styles.securedImage} src={'/missing-image.png'} alt={"missing image"}/>
        )
    );
}

export default SecuredImage