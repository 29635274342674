import React from "react";
import {Grid2} from "@mui/material";
import {GalleryImageDTO} from "../SocietyGallery";
import useSociety from "../../../../hooks/useSociety";
import {mapCategoriesPathToIds} from "../../articles/articlesUtils";
import GalleryMediaBox from "./galleryMediaBox/GalleryMediaBox";

interface MyImageListProps {
    categoryId: number,
    galleryImages: GalleryImageDTO[],
}

const MyImageList = ({categoryId, galleryImages}: MyImageListProps) => {

    const {society} = useSociety();

    const filteredGalleryImages = galleryImages.filter((galleryImage: GalleryImageDTO) => {
        if (categoryId === 0) {
            return true;
        }

        return galleryImage.category?.id && mapCategoriesPathToIds(society.galleryImageCategories!, galleryImage.category.id)?.includes(categoryId)
    });

    return (
        <Grid2 container columnSpacing={4} mt={4} rowSpacing={4}>
            {filteredGalleryImages && filteredGalleryImages.length > 0 && filteredGalleryImages.map((galleryImageDTO: GalleryImageDTO) => (
                <GalleryMediaBox key={galleryImageDTO.id} galleryImageDTO={galleryImageDTO}/>
            ))}

        </Grid2>
    );
}

export default MyImageList