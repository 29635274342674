import React from "react";
import styles from "./Services.module.css";
import Typography from "@mui/material/Typography";
import LineOfServices from "./LineOfServices/LineOfServices";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import Box from "@mui/material/Box";

const societiyServices = [
    {
        title: 'Site vitrine',
        image: '/web-browser.png',
        text: 'Tu ne possèdes pas de site Internet et ne souhaites pas passer des heures à en créer un ? Looty te permet de mettre en place votre page en quelques clics seulement !',
    },
    {
        title: 'Vente en ligne',
        image: '/online-shopping.png',
        text: 'Propose tes articles en ligne en offrant la possibilité à tes clients de payer par Twint ou par carte de crédit. Gère ton stock, le mode de livraison et restez informé en temps réel lorsqu’une commande est réalisée.'
    },
    {
        title: 'Point de retrait',
        image: '/store2.png',
        text: 'Tu ne souhaites pas gérer l’expédition de tes articles ? Pas de problèmes, définis un point de retrait ainsi que des horaires de passage et ce sont tes clients qui passeront retirer leur commande.'
    },
    {
        title: 'Rendez-vous',
        image: '/calendar.png',
        text: 'Que tu sois coiffeur, masseur ou physio, tes clients pourront réserver leur rendez-vous sans avoir à te téléphoner. Synchronise ton agenda avec ton téléphone et modifie à tout moment tes disponibilités.'
    },
]


const Services = () => {

    const {servicesSection} = useSectionsRefs();

    return (
        <Box
            ref={servicesSection}
            sx={{
                backgroundColor: 'tertiary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >


            <div className={styles.content}>
                <Typography
                    sx={{
                        fontSize: {xs: 30, sm: 40},
                    }}
                    fontWeight={400}
                    variant={"thirdVariant"}
                >
                    SERVICES
                </Typography>

                <Typography
                    sx={{
                        fontSize: {xs: 22, sm: 26, md: 30},
                        mt: 2,
                        mb: 4,
                    }}
                    fontWeight={400}
                    variant={"thirdVariant"}
                >
                    Pour la gestion de votre société
                </Typography>

                <LineOfServices servicesDescriptions={societiyServices}/>

            </div>
        </Box>
    );
}

export default Services