import React, {useState} from "react";
import {Grid2} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import {ServiceDTO} from "../SocietyHomepageServices";
import CustomTextField from "../../../customMUIComponents/CustomTextField";
import Box from "@mui/material/Box";
import Done from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import {DeleteOutline} from "@mui/icons-material";
import useSociety from "../../../../hooks/useSociety";
import DeleteServiceModal from "./deleteServiceModal/DeleteServiceModal";
import styles from "./ServiceDescription.module.css";
import useDataPut from "../../../../hooks/useDataPut";

interface ServiceDescriptionProps {
    service: ServiceDTO
}

const ServiceDescription = ({service}: ServiceDescriptionProps) => {

    const {society} = useSociety();

    const [openDeleteService, setOpenDeleteService] = useState<boolean>(false);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [serviceTitle, setServiceTitle] = useState<string>(service.title);
    const [serviceDescription, setServiceDescription] = useState<string>(service.description);

    const handleCloseEditingMode = () => {
        setIsEditMode(false);
        setServiceTitle(service.title);
        setServiceDescription(service.description);
    }

    const {putDataPromise} = useDataPut(`/societies/${society.id}/services/${service.id}`, {}, true, false, false);

    const handleUpdateService = () => {
        putDataPromise(undefined, {
            title: serviceTitle,
            description: serviceDescription
        }).then((response) => {
            window.location.reload();
        })
    }

    return (
        <Grid2 key={service.title} size={12} mt={4} container>

            {isEditMode ? (
                <form onSubmit={handleUpdateService} className={styles.form}>
                    <Grid2 container size={12}>
                        <Box width={{xs: '240px', sm: '350px'}}>
                            <CustomTextField
                                value={serviceTitle}
                                label={'Titre du service'}
                                setField={setServiceTitle}
                            />
                        </Box>
                    </Grid2>

                    <Box width="100%" mt={4}>
                        <CustomTextField
                            value={serviceDescription}
                            label={'Description du service'}
                            setField={setServiceDescription}
                            multiline={true}
                            rows={5}
                        />
                    </Box>

                    <Box sx={{margin: 'auto'}}>
                        <IconButton
                            aria-label="validate"
                            variant="secondVariant"
                            type={"submit"}
                        >
                            <Done sx={{
                                fontSize: {xs: '18px', sm: '20px'}
                            }}/>
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            variant="secondVariant"
                            onClick={handleCloseEditingMode}
                        >
                            <Close sx={{
                                fontSize: {xs: '18px', sm: '20px'}
                            }}/>
                        </IconButton>
                    </Box>

                </form>
            ) : (
                <>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        alignItems: 'center',
                    }}>
                        <Typography
                            sx={{
                                mt: 0,
                                fontSize: {xs: 18, sm: 20, md: 25},
                            }}
                            variant={"fourthVariant"}
                            textAlign={"left"}
                        >
                            {service.title}
                        </Typography>

                        {society.secured && (
                            <>
                                <IconButton
                                    aria-label="Edit"
                                    variant="secondVariant"
                                    onClick={() => {
                                        setIsEditMode(true);
                                    }}
                                >
                                    <Edit sx={{
                                        fontSize: {xs: '18px', sm: '20px'}
                                    }}/>
                                </IconButton>
                                <IconButton
                                    aria-label="Delete"
                                    variant="secondVariant"
                                    onClick={() => {
                                        setOpenDeleteService(true);
                                    }}
                                >
                                    <DeleteOutline sx={{
                                        fontSize: {xs: '18px', sm: '20px'}
                                    }}/>
                                </IconButton>
                                <DeleteServiceModal open={openDeleteService} setOpen={setOpenDeleteService}
                                                    serviceId={service.id}/>
                            </>
                        )}


                    </Box>

                    <Typography
                        sx={{
                            textAlign: 'left',
                            fontSize: {xs: 14, sm: 16, md: 18},
                        }}
                        variant={"fourthVariant"}
                    >
                        {service.description}
                    </Typography>
                </>
            )}

        </Grid2>
    );
}

export default ServiceDescription