import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid2} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {OrderAdminDTO} from "../SocietyAdminOrders";
import useFormatNumber from "../../../../hooks/useFormatNumber";
import CartVerificationItem from "../../../checkoutStepper/cartVerification/cartVerificationItem/CartVerificationItem";
import AdminOrdersDetailsItem from "../adminOrdersDetailsItem/AdminOrdersDetailsItem";


interface AdminOrdersDetailsModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    order: OrderAdminDTO
}

const defaultOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
};

const AdminOrdersDetailsModal = ({open, setOpen, order}: AdminOrdersDetailsModalProps) => {

    const {formatCentsToPrice} = useFormatNumber();

    return (
        <Dialog
            disablePortal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                <Typography variant={"firstVariant"} fontSize={{xs: 18, sm: 20, md: 22}}>
                    Commande #{order.id}
                </Typography>
            </DialogTitle>
            <DialogContent >
                <Typography
                    fontSize={"18px"}
                    variant={"firstVariant"}
                    textAlign={"left"}
                >
                    Général
                </Typography>
                <Grid2 container>
                    <Grid2 container size={12} sx={{
                        backgroundColor: '#f4f4f4',
                        padding: '5px',
                        borderRadius: '5px'
                    }}>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                Date: {new Date(order.createdAt).toLocaleDateString('fr-FR', defaultOptions)}
                            </Typography>
                        </Grid2>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                Total: {formatCentsToPrice(order.totalPrice)}CHF
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Typography
                    mt={2}
                    fontSize={"18px"}
                    variant={"firstVariant"}
                    textAlign={"left"}
                >
                    Client
                </Typography>
                <Grid2 container>
                    <Grid2 container size={12} sx={{
                        backgroundColor: '#f4f4f4',
                        padding: '5px',
                        borderRadius: '5px'
                    }}>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                {order.firstname}
                            </Typography>
                        </Grid2>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                {order.lastname}
                            </Typography>
                        </Grid2>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                {order.email}
                            </Typography>
                        </Grid2>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                {order.phoneNumber}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Typography
                    mt={2}
                    fontSize={"18px"}
                    variant={"firstVariant"}
                    textAlign={"left"}
                >
                    Adresse
                </Typography>
                <Grid2 container>
                    <Grid2 container size={12} sx={{
                        backgroundColor: '#f4f4f4',
                        padding: '5px',
                        borderRadius: '5px'
                    }}>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                {order.city} {order.zip}
                            </Typography>
                        </Grid2>
                        <Grid2 container size={12}>
                            <Typography
                                fontSize={"14px"}
                                textAlign={"left"}
                                variant={"firstVariant"}
                            >
                                {order.street} {order.streetNumber}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>

                <Typography
                    mt={2}
                    fontSize={"18px"}
                    variant={"firstVariant"}
                    textAlign={"left"}
                >
                    Détails
                </Typography>

                <Grid2 container>

                    <Grid2 container size={12} mt={1} mb={2} display={{xs: 'none', sm: 'flex'}} sx={{borderBottom: '1px solid gray'}} pb={2}>
                        <Grid2 size={2} sx={{border: '0px solid black'}}>
                            <Typography
                                variant={"firstVariant"}
                                textAlign={"left"}
                            >
                                Image
                            </Typography>
                        </Grid2>
                        <Grid2 offset={1} size={4} sx={{border: '0px solid black'}}>
                            <Typography
                                variant={"firstVariant"}
                                textAlign={"left"}
                            >
                                Article
                            </Typography>
                        </Grid2>
                        <Grid2 size={2} sx={{border: '0px solid black'}}>
                            <Typography
                                variant={"firstVariant"}
                                textAlign={"left"}
                            >
                                Quantité
                            </Typography>
                        </Grid2>
                        <Grid2 size={2} sx={{border: '0px solid black'}}>
                            <Typography
                                variant={"firstVariant"}
                                textAlign={"left"}
                            >
                                Prix
                            </Typography>
                        </Grid2>
                        <Grid2 size={1} sx={{border: '0px solid black'}}>
                            <Typography
                                variant={"firstVariant"}
                                textAlign={"left"}
                            >
                                Total
                            </Typography>
                        </Grid2>
                    </Grid2>

                    {order.orderItems.map((orderItem) => {
                        return (
                            <AdminOrdersDetailsItem key={orderItem.id} orderItem={orderItem} orderId={order.id}/>
                        )
                    })}
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdminOrdersDetailsModal