import React from "react";
import {Avatar, Menu, MenuItem, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useSociety from "../../../hooks/useSociety";
import {useNavigate} from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import useAuth from "../../../hooks/useAuth";

interface CustomAvatarProps {
    xs?: string,
    sm?: string,
}

const CustomAvatar = ({xs = 'block', sm = 'block'}: CustomAvatarProps) => {

    const logout = useLogout();

    const handleLogout = () => {
        logout();
    }

    const navigate = useNavigate();

    const settings = [
        {
            title: 'Profil',
            action: () => navigate('/profile')

        },
        {
            title: 'Déconnexion',
            action: () => handleLogout(),
        },
    ];

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const {society} = useSociety();
    const {auth} = useAuth();

    if (auth.societyId === society.id) {
        settings.splice(
            1,
            0,
            {
                title: 'Tableau de bord',
                action: () => navigate('/dashboard')

            })
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuClick = (setting: any) => {
        handleCloseUserMenu();
        setting.action();
    }

    return (
        <Box>
            <Tooltip title="Profil">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <Avatar
                        sx={{ width: 30, height: 30 }}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting.title} onClick={() => handleMenuClick(setting)}>
                        <Typography
                            variant={"secondVariant"}
                            sx={{textAlign: 'center'}}
                        >
                            {setting.title}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default CustomAvatar