import React, {useEffect} from "react";
import styles from "./Conditions.module.css"
import Box from "@mui/material/Box";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import Typography from "@mui/material/Typography";
import {SUMMARY} from "./cond_data";
import {List, ListItemIcon} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {Circle} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";

const Conditions = () => {

    const {setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    useEffect(() => {
        setDisplayRegisterSocietyButton(false);
    }, [setDisplayRegisterSocietyButton, setNavItems]);

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'primary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "primary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        <Typography
                            fontSize={{xs: '25px', sm: '30px'}}
                            variant={'secondVariant'}
                        >
                            Conditions générales
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            mt={4}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            {SUMMARY}
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Protection des données
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Conformément aux dispositions légales suisses, nous nous engageons à respecter la
                            confidentialité de vos informations personnelles et à les utiliser uniquement aux fins pour
                            lesquelles vous nous les communiquez. Nous mettons en œuvre des mesures techniques et
                            organisationnelles appropriées pour préserver vos données de toute perte, accès non
                            autorisé, divulgation, altération ou destruction. Nous ne partageons pas vos informations
                            personnelles avec des tiers, sauf si cela est nécessaire pour vous fournir les services que
                            vous avez demandés ou si nous y sommes légalement obligés.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Cookies
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Ce site utilise des cookies assurant le tracking de différentes données. D’une part, des
                            cookies de performance et de mesure d’audience, d’autre part, des cookies tiers pour le
                            partage sur les réseaux sociaux. Les cookies sont de petits fichiers texte placés sur votre
                            ordinateur qui permettent au site d’analyser votre visite et de se souvenir de vous et de
                            vos préférences dans le but d’améliorer votre expérience de navigation.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Copyright
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Le site web Looty.ch appartient à Looty. Aucune donnée présente sur ce site ne peut être
                            utilisée, modifiée ou reproduite sans le consentement écrit de Looty. Sont considérés comme
                            données tous les textes, images, vidéos, sons et codes sources.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Responsabilité
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty ne peut être tenue pour responsable d’un quelconque préjudice pouvant être causé par
                            l’utilisation de ce site et décline toute responsabilité pouvant en résulter. Nous déclinons
                            également toute responsabilité à l’égard des pratiques des sites liés et vous incitons
                            vivement à consulter leur politique de confidentialité avant d’y délivrer vos informations
                            personnelles.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Validité et modification
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty peut réviser et mettre à jour ces conditions d'utilisation à tout moment. Votre
                            utilisation continue du site web après toute modification de ces conditions d'utilisation
                            sera considérée comme une acceptation de ces modifications. Tout aspect du site web peut
                            être modifié, complété, supprimé ou mis à jour sans préavis, à la seule discrétion de Looty.
                            Looty peut établir ou modifier, à tout moment, des pratiques générales et des restrictions
                            concernant d'autres services Looty à sa seule discrétion. Les changements importants seront
                            annoncés sur notre site web.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Responsabilité de l'utilisateur
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Vous garantissez que vous êtes légalement apte à accepter les présentes conditions
                            d'utilisation.<br/><br/>


                            Vous déclarez que les informations que vous fournissez, qu'il s'agisse d'informations
                            relatives à votre identité personnelle ou à celle d'autres personnes, sont, à votre
                            connaissance, exactes et sincères. Vous êtes informé que l'utilisation d'une fausse identité
                            et la diffusion de fausses informations peuvent donner lieu à des poursuites civiles et/ou
                            pénales.<br/><br/>


                            Vous vous assurez que tout appareil à partir duquel vous accédez au site web est sécurisé et
                            équipé d'un logiciel antivirus à jour.<br/><br/>


                            Les comptes du site Looty doivent être strictement personnels. Néanmoins, certaines
                            situations peuvent nécessiter qu'un utilisateur prenne un rendez-vous et des services au nom
                            d'une autre personne, comme par exemple des enfants ou des personnes sous tutelle. Dans ce
                            cas, vous devez vous assurer que toutes les personnes accédant à Looty à travers leur
                            connexion sont conscientes de ces conditions d'utilisation et les respectent.<br/><br/>


                            Vous êtes responsable de l'utilisation qui est faite de votre nom d'Utilisateur et de votre
                            mot de passe. Looty vous recommande donc d'utiliser un mot de passe unique et de le garder
                            soigneusement secret.<br/><br/>


                            Vous n'avez pas le droit de laisser d'autres personnes utiliser votre compte.<br/><br/>


                            Vous acceptez de ne pas accorder de licence, de sous-licence, de vendre, de revendre, de
                            louer, de transférer, de céder, de distribuer ou de partager dans le temps les droits
                            accordés par les présentes conditions d'utilisation à un tiers.<br/><br/>


                            Vous acceptez de ne pas modifier, adapter ou pirater le site web, ni de l'utiliser d'une
                            manière illégale, y compris, mais sans s'y limiter, en violation des droits de toute
                            personne en matière de protection de la vie privée.<br/><br/>


                            Vous acceptez de ne pas tenter de déchiffrer, de décompiler, de faire de l'ingénierie
                            inverse ou de découvrir de quelque manière que ce soit le code source de l'un des services
                            de Looty ou du site Web.

                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Responsabilité de Looty
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty s'efforcera de fournir aux Utilisateurs un service de qualité et de résoudre dans les
                            meilleurs délais les éventuelles défaillances techniques. L’Utilisateur est informé que les
                            systèmes de transmission des communications, les systèmes informatiques, les services
                            d'hébergement ou les ordinateurs des Utilisateurs Professionnels peuvent, comme tout système
                            informatique, être sujets à des défaillances.<br/><br/>

                            Tous les services, qu'ils soient réservés ou payés par le biais du site Web ou en personne,
                            sont fournis par nos utilisateurs et, en tant que tels, nous ne pouvons être tenus
                            responsables de la qualité, de l'exécution ou des dommages potentiels / blessures qui
                            pourraient découler de ces services. Looty n'est pas responsable de l'exactitude des
                            informations fournies par l’Utilisateur concernant la date et l'heure du rendez-vous ou
                            toute autre information. Looty n'a aucune responsabilité pour gérer les annulations d'un
                            utilisateur ou d'un client à quelques minutes de ces rendez-vous, qui doivent être gérés
                            directement entre l’Utilisateur et le client. Toutes les photos et informations figurant sur
                            la plateforme sont affichées à des fins d'illustration seulement. Bien que nous demandions à
                            nos Utilisateurs de fournir des informations et/ou une description de service aussi précises
                            que possible, nous ne vérifions pas ces informations et ne pouvons être tenus responsables
                            de tout contenu trompeur.<br/><br/>

                            Vous acceptez de renoncer à tout recours contre Looty du fait d'une défaillance technique
                            dans l'utilisation des services proposés sur le Site ou d'une défaillance dans l'utilisation
                            par l’Utilisateur Professionnel. Looty met en œuvre les meilleures pratiques pour assurer la
                            haute disponibilité, la confidentialité et l'intégrité de ses services, mais Looty n'est
                            tenue qu'à une obligation de moyens à cet égard.<br/><br/>

                            Looty s'engage à respecter votre vie privée et à protéger vos informations personnelles
                            conformément à toutes les lois applicables.

                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Droit de propriété intellectuelle
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Tous les droits, y compris les droits de propriété intellectuelle sur le site Web demeurent
                            en tout temps la propriété unique et exclusive de Looty, et l’utilisateur n'obtient aucun
                            droit, titre ou intérêt dans le contenu du site Web. Vous reconnaissez par la présente que
                            Looty conserve tous les droits, y compris les droits de propriété intellectuelle dans et
                            pour toute modification du site Web et de ses services, mise à jour, mise à niveau ou
                            nouvelle version, et que tous les droits de propriété intellectuelle sur le site Web seront
                            et resteront la propriété exclusive de Looty. Looty accorde aux utilisateurs un droit
                            personnel, restreint, non exclusif, non transférable, non cessible, sans droit de
                            sous-licence, d'accéder et d'utiliser le site Web à des fins personnelles.<br/><br/>

                            Aux fins des présentes conditions d'utilisation, on entend par "droits de propriété
                            intellectuelle", sans limitation, tous les éléments suivants :<br/><br/>
                        </Typography>

                        <List dense>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="tous les droits sur les inventions (qu'elles soient brevetables ou non et qu'elles aient été ou non mises en pratique), y compris tous les brevets, demandes de brevets (y compris les demandes de brevets provisoires et les demandes de brevets PCT) et divulgations de brevets, ainsi que toutes les redélivrances, continuations,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="toutes les œuvres protégées par le droit d'auteur, tous les droits d'auteur et toutes les demandes, enregistrements et renouvellements y afférents,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="les droits sur les bases de données,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="les droits sur les dessins et modèles,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="les droits sur les topographies et les masques,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="les droits sur les modèles d'utilité,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="toutes les marques de commerce, marques de service, habillages commerciaux, logos, noms commerciaux et dénominations sociales, ainsi que toutes les traductions, adaptations, dérivations et combinaisons de ceux-ci, y compris tout le fonds de commerce qui y est associé, et toutes les demandes, enregistrements et renouvellements y afférents,"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 0, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="tous les secrets commerciaux et informations commerciales confidentielles, ainsi que toute la recherche et le développement et les autres formes de protection des droits de propriété intellectuelle et industrielle, qu'ils soient enregistrés ou non et y compris toutes les demandes y afférentes, reconnus en vertu de toute loi applicable dans tous les pays du monde entier."
                                />
                            </ListItem>
                        </List>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Vous vous engagez à ne pas supprimer ou modifier les marques de propriété présentes sur le site Web, visibles pendant son fonctionnement, y compris les mentions de copyright, les marques présentes sur les supports ou toute documentation.<br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty sera le seul propriétaire de tous les droits de propriété intellectuelle créés, préparés, produits ou développés par Looty dans le cadre du site Web ou en relation avec celui-ci.<br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Vous ne devez pas transmettre, modifier, publier, copier, rapporter, distribuer ou reproduire sous une autre forme tout ou partie du contenu du site web sans l'accord écrit préalable du titulaire des droits.
                        </Typography>


                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Données utilisateur
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            L’utilisateur accorde à Looty une licence non exclusive, transférable, mondiale, irrévocable et libre de redevances pour l'utilisation des données de l’utilisateur :<br/><br/>
                        </Typography>

                        <List dense>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="dans la mesure où cela est nécessaire pour la fourniture de services sur le site web ; et"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 0, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="de fournir tout service supplémentaire demandé par l’utilisateur."
                                />
                            </ListItem>
                        </List>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            "Données Utilisateur" fait référence à toutes les données et informations de toute natures transmises à Looty par l’Utilisateur, ou reçues par Looty au nom de l’Utilisateur, en relation avec les services sur le site Web.<br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Les données de l’utilisateur hébergées par Looty dans le cadre des services du site Web, ainsi que tous les droits de propriété intellectuelle mondiaux y afférents, demeurent la propriété exclusive de l’Utilisateur.<br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            L’utilisateur accepte que Looty puisse recueillir, utiliser et divulguer des données quantitatives dérivées de l'utilisation des services sur le site Web à des fins d'analyse de l'industrie, d'analyse comparative, d'analyse, de marketing et d'autres fins commerciales. Toutes les données recueillies, utilisées et divulguées seront sous forme agrégée seulement.<br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            L’utilisateur déclare et garantit qu'il est le propriétaire légitime des données de l’utilisateur et/ou qu'il dispose des droits d'utilisation et de l'autorité suffisants pour les accorder à Looty, afin de permettre à Looty de les utiliser aux fins de la fourniture des services sur le site Web.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Contenu créé par l'utilisateur
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            En publiant du contenu sur le site web de Looty, vous acceptez de respecter les règles et toutes les conditions d'utilisation énoncées ici. Looty peut utiliser et placer ce contenu à sa seule discrétion (par exemple pour le marketing, la promotion ou l'amélioration de nos services) sur notre site Web ou de telles plates-formes de médias sociaux, des bulletins d'information, des promotions spéciales). Nous nous réservons le droit d'ajuster, de refuser ou de supprimer, en tout ou en partie, tout contenu qui enfreint ces règles ou nos conditions d'utilisation, ou pour toute autre raison, à notre seule discrétion. En cas de violations répétées et graves, nous pouvons, à notre seule discrétion, suspendre ou supprimer définitivement le(s) compte(s) en question. Vous pouvez nous contacter ou nous signaler les messages qui enfreignent notre politique d'évaluation. Nous n'autorisons aucun type de contenu suivant :<br/><br/>
                        </Typography>

                        <List dense>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Les blasphèmes, propos sexuellement explicites, l’incitation à la haine, la discrimination, les menaces, la violence, la pornographie ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="La mention des noms et prénoms, ou toute forme d’attaque personnelle envers les membres ou le personnel de Looty et/ou le personnel des utilisateurs"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Toute forme de spam, contact indésirable ou contenu diffusé de manière répétée ou susceptible de déranger ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Toute forme de contenu qui tolère ou encourage des activités illégales, nuisibles, profanes, vulgaires, obscènes, menaçantes ou diffamatoires ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Tout contenu illégal qui viole les droits d'un tiers ou d'une entité, y compris les droits de propriété intellectuelle et les droits à la vie privée"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 0, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Tout contenu suffisamment explicite pour identifier l'adresse d'un logement ou tout autre contenu qui pourrait constituer un risque pour la sécurité personnelle d'un membre de la communauté de Looty."
                                />
                            </ListItem>
                        </List>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Responsabilité générale et indemnisation
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Dans la mesure maximale permise par la loi applicable, Looty ne sera jamais tenu responsable (que ce soit dans le cadre d'un contrat, d'une garantie, d'un délit civil (y compris à la suite d'une négligence, de la responsabilité du produit ou d'une autre théorie)) de tout dommage indirect, punitif, accessoire, spécial ou consécutif ou de tout autre dommage similaire résultant d'une perte de données ou d'une interruption des activités résultant de l'utilisation et/ou de l'incapacité d'utiliser le site Web, (y compris les dommages liés à la perte de bénéfices, de revenus, d'affaires, de contrats ou de clients, à la perte ou à la corruption de données, à la perte de clientèle, aux dommages causés à l'équipement et à la réputation, à la perte d'opportunités, à la perte d'économies anticipées et au coût de l'obtention de biens ou de services de remplacement).
                            L’utilisateur accepte que Looty ne puisse être tenu responsable uniquement selon les termes de cette clause dans la mesure où les dommages subis par l’utilisateur sont directement et uniquement attribuables à Looty. Cependant, rien dans l'accord ne doit limiter ou exclure la responsabilité de Looty pour (i) négligence grave, (ii) faute intentionnelle ou (iii) fraude.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Sans limiter la généralité de ce qui précède, nous ne sommes pas responsables, et vous acceptez de ne pas nous tenir pour responsables, de tout dommage ou perte (y compris, mais sans s'y limiter, la perte d'argent, de clientèle ou de réputation, de bénéfices, d'autres pertes intangibles, ou tout dommage spécial, indirect ou consécutif) résultant directement ou indirectement de ce qui suit :
                            <br/><br/>
                        </Typography>

                        <List dense>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Le contenu que vous fournissez (directement ou indirectement) en utilisant notre site web ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Votre utilisation ou votre incapacité à utiliser notre site web ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Des retards ou des perturbations sur notre site web ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Les problèmes, bogues, erreurs ou inexactitudes de quelque nature que ce soit dans notre site web ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Les dommages causés à votre matériel informatique par l'utilisation des services de notre site web ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Le contenu, les actions ou l'inaction de tiers, y compris les articles répertoriés sur notre site web ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 3, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Une suspension ou toute autre mesure prise à l'égard de votre compte ou d'une violation des conditions d'utilisation ;"
                                />
                            </ListItem>
                            <ListItem sx={{mt: -3, mb: 0, alignItems: 'baseline'}}>
                                <ListItemIcon sx={{
                                    color: 'primary.main',
                                }}>
                                    <Circle sx={{
                                        height: '8px',
                                        width: '8px',
                                    }}/>
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    sx={{
                                        fontSize: {xs: '14px', sm: '16px'},
                                        fontFamily: 'Insignia',
                                        color: 'primary.main',
                                        lineHeight: 1.1,
                                    }}
                                    primary="Votre besoin de modifier vos pratiques, votre contenu ou votre comportement, ou votre perte de ou incapacité à exercer votre activité, à la suite de modifications apportées aux présentes conditions d'utilisation ou à nos politiques."
                                />
                            </ListItem>
                        </List>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Paiement
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Vous pouvez payer pour des services en utilisant les méthodes de paiement mis à disposition par Looty et Looty gérera le règlement du paiement en faveur des utilisateurs qui proposent les services sur notre site Web. Lorsque vous achetez ou commandez des services sur le site web, vous nous autorisez à initier les paiements en utilisant la méthode de paiement que vous avez choisie et à collecter les montants de la transaction en notre nom ou au nom de l’utilisateur. Vous acceptez également de payer les frais applicables aux services que vous commandez au fur et à mesure qu'ils deviennent exigibles, ainsi que toutes les taxes y afférentes, et de nous rembourser tous les frais de recouvrement et les intérêts pour tous les montants en souffrance.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty s'engage à mettre en œuvre et à maintenir des mesures techniques et organisationnelles rigoureuses pour assurer un accès et une utilisation sûrs de la plate-forme de traitement des paiements, mais ne peut pas garantir un accès sécurisé continu à tout moment, ni garantir que les fonctions et la plate-forme de paiement hébergées par le site Web sont exemptes de composants nocifs.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty peut sauvegarder les informations de paiement, telles que les numéros de carte de crédit ou de débit, et les dates d'expiration de la carte, saisies par vous lorsque vous effectuez une réservation liée à un paiement, achetez un bon, achetez un abonnement, ou effectuez toute autre transaction initiée à partir de notre site Web. S'il y a un différend entre vous et un fournisseur de paiement, vous comprenez et acceptez que Looty n'a aucune obligation de s'impliquer. Looty n'endosse pas, n'est pas responsable de, et ne fait aucune représentation quant à tout aspect de ce fournisseur de paiement. Looty n'est pas responsable de tout dommage ou perte causé ou prétendument causé par ou en relation avec votre utilisation des services des fournisseurs de paiement.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty ne peut pas être tenu responsable des frais de change ou des suppléments et n'effectuera aucune compensation ou remboursement pour les frais imposés par votre banque ou l'émetteur de votre carte.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Dans certains cas, votre transaction peut être refusée, gelée ou retenue pour quelque raison que ce soit, y compris en cas de fraude présumée, de conformité à la loi sur le blanchiment d'argent, de conformité aux sanctions économiques ou commerciales, dans le cadre des contrôles de risque internes de Looty, ou en raison de violations potentielles de toute politique de Looty.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Vous acceptez de respecter, et de ne pas amener un tiers à violer, toutes les lois, réglementations, règles et conditions applicables en rapport avec l'utilisation des services fournis. Vous comprenez que certains tiers, tels que les émetteurs de cartes de crédit et de débit, les réseaux de cartes de crédit et de débit et les prestataires de services de paiement, peuvent avoir leurs propres conditions générales pour les méthodes de paiement ou de règlement que vous choisissez d'utiliser dans le cadre des transactions de paiement gérées. Le non-respect des conditions générales des tiers peut entraîner des frais qui vous sont imposés ou d'autres mesures prises par ces tiers, et vous acceptez que Looty n'ait aucun contrôle sur ces frais ou ces mesures, et qu'il n'en soit pas responsable. Looty n'endosse pas, n'est pas responsable et ne fait aucune représentation quant à tout aspect des services de ces fournisseurs de paiement, y compris, sans s'y limiter, la façon dont le fournisseur de paiement traite les données, y compris vos données, ou toute interaction entre vous et le fournisseur de paiement.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Résiliation
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Looty se réserve le droit, à sa seule discrétion, de résilier votre compte sur le site Web, de supprimer les données de l’Utilisateur et de restreindre votre utilisation de tout ou partie du site Web pour quelque raison que ce soit, sans préavis, sans remboursement et sans responsabilité à votre égard ou à l'égard de quiconque. Si l’utilisateur ne se connecte pas au site Web pendant 12 mois ou plus, Looty peut considérer le compte comme "inactif" et supprimer définitivement le compte, les données de l’utilisateur et toutes les autres données qui y sont associées.
                            <br/><br/>
                        </Typography>

                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Nous pouvons suspendre ou mettre fin à votre utilisation du compte du site web si vous ne respectez pas les présentes conditions ou notre documentation, si le paiement de l'abonnement n'a pas été effectué ou si vous utilisez le site web d'une manière susceptible d'engager notre responsabilité juridique ou de perturber l'utilisation du site web par d'autres personnes.
                            Si vous souhaitez résilier votre abonnement lors d’une période en cours, ceci ne donnera pas lieu à un remboursement partiel pour la période restante.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Litiges
                        </Typography>


                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Tout litige ou toute réclamation concernant de quelque manière que ce soit le (pré)paiement sera résolu sur la base des présentes conditions d'utilisation de Looty. Looty n'a aucune obligation de vous fournir un avoir ou un remboursement dans le cas où vous ne seriez pas satisfait des services de l'un de nos utilisateurs. De ce qui précède, nous prenons votre expérience au sérieux et, en cas de désaccord, nous vous conseillons vivement de vous adresser directement à l’utilisateur.
                            Si le problème n'est pas résolu, vous pouvez toujours utiliser la section "avis" de notre site pour exprimer votre mécontentement. Enfin, vous pouvez contacter Looty pour tenter de résoudre le problème avec l’Utilisateur. Dans des circonstances exceptionnelles, et à la seule discrétion de Looty, nous pouvons décider de rembourser/créditer le montant total ou partiel du prépaiement. Ce qui précède ne doit être considéré que comme un geste de bonne volonté et ne constitue en aucun cas une responsabilité envers vous pour les services fournis par nos Utilisateurs.
                        </Typography>

                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Clarification
                        </Typography>


                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Si vous avez des questions ou des préoccupations concernant nos conditions générales, n’hésitez pas à nous contacter à info@looty.ch Merci d’avoir pris le temps d’examiner cette page.
                        </Typography>
                        {/*----------------------------------------------------------*/}

                        <Typography
                            mt={4}
                            textAlign={"left"}
                            fontSize={{xs: '20px', sm: '28px'}}
                            variant={'secondVariant'}
                        >
                            Dernière version
                        </Typography>


                        <Typography
                            className={styles.cond_content}
                            textAlign={'justify'}
                            fontSize={{xs: '14px', sm: '16px'}}
                            variant={'secondVariant'}
                        >
                            Ces conditions générales ont été éditées par Looty le 10.03.2025.
                        </Typography>



                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default Conditions