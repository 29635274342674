import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {CategoryDTO} from "../../../../context/SocietyProvider";
import useSociety from "../../../../hooks/useSociety";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import useDataDelete from "../../../../hooks/useDataDelete";

interface SocietyAdminCategoriesDeletionModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    category: CategoryDTO,
    isArticleCategory: boolean, // otherwise, gallery media category
}

const SocietyAdminCategoriesDeletionModal = ({open, setOpen, category, isArticleCategory}: SocietyAdminCategoriesDeletionModalProps) => {
    const {society} = useSociety();

    const {deleteData} = useDataDelete('', true, false);

    const handleDelete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        deleteData(`/societies/${society.id}/${isArticleCategory ? 'categories' : 'gallery-categories'}/${category.id}`).then(() => {
            window.location.reload();
        })
    }

    const handleClose = () => {
        setOpen(false);
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    textAlign: "center",
                }}
            >
                <DialogTitle sx={{mt: -5}}>
                    Ajouter un article
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => handleDelete(e)}>
                        <Typography
                            id="modal-modal-title"
                            variant="secondVariant"
                            component="h2"
                            sx={{marginBottom: 2}}
                            fontSize={{xs: 22, sm: 24, md: 26}}
                        >
                            Supprimer une catégorie
                        </Typography>


                        <Typography
                            variant={"thirdVariant"}
                            fontSize={{xs: 16, sm: 18, md: 20}}
                            textAlign={"left"}
                            mt={2}
                        >
                            Vous êtes sur le point de supprimer défénitivement la catégorie suivante: {category.name}
                        </Typography>


                        <Typography
                            variant={"thirdVariant"}
                            fontSize={{xs: 16, sm: 18, md: 20}}
                            textAlign={"left"}
                            mt={2}
                        >
                            Ses sous-catégories seront également supprimées. Les articles, eux, ne seront pas supprimés.
                        </Typography>

                        <Typography
                            variant={"thirdVariant"}
                            fontSize={{xs: 16, sm: 18, md: 20}}
                            textAlign={"left"}
                            mt={2}
                        >
                            Cette action est irréversible.
                        </Typography>

                        <Button
                            type="submit"
                            variant="fifthVariant"
                            fullWidth
                            sx={{
                                mt: 2
                            }}
                        >
                            Supprimer définitivement
                        </Button>
                    </form>

                </DialogContent>
            </Dialog>
        </>
    );
}

export default SocietyAdminCategoriesDeletionModal