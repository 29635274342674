import React, {useState} from "react";
import styles from "./SocietyHomepageServices.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Grid2} from "@mui/material";
import Button from "@mui/material/Button";
import useSociety from "../../../hooks/useSociety";
import AddServiceModal from "./addServiceModal/AddServiceModal";
import ServiceDescription from "./serviceDescription/ServiceDescription";
import useDataFetch from "../../../hooks/useDataFetch";

export interface ServiceDTO {
    id: number,
    title: string,
    description: string,
}

const SocietyHomepageServices = () => {

    const {society} = useSociety();

    const [openAddService, setOpenAddService] = useState<boolean>(false);

    const {data: services} = useDataFetch<ServiceDTO[]>(`/societies/${society.id}/services`, false, true);

    return (

        !society.secured && services && services.length === 0 ? (
            <></>
        ) : (
            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <Typography
                        sx={{
                            fontSize: {xs: 30, sm: 40},
                        }}
                        variant={"fourthVariant"}
                    >
                        SERVICES
                    </Typography>

                    {(society.secured && services && services.length === 0) && (
                        <Typography
                            textAlign={"left"}
                            sx={{
                                fontSize: {xs: 18, sm: 20, md: 24},
                                mt: 4,
                            }}
                            variant={"fourthVariant"}
                        >
                            Les services permettent de lister les différentes activités que réalise ta société.
                            Cette section est optionnelle et n'est visible que pour toi tant qu'aucun service n'a été
                            créé.
                        </Typography>
                    )}

                    {services && (
                        <Grid2 container mb={2}>
                            {services.map((service: ServiceDTO) => (
                                <ServiceDescription service={service} key={service.id}/>
                            ))}
                        </Grid2>
                    )}


                    {society.secured && (
                        <Button
                            sx={{
                                mt: 2,
                                mb: 4,
                            }}
                            variant={"fourthVariant"}
                            onClick={() => setOpenAddService(true)}
                        >
                            Ajouter un service
                        </Button>
                    )}

                    <AddServiceModal open={openAddService} setOpen={setOpenAddService}/>

                </div>
            </Box>
        )
    );
}

export default SocietyHomepageServices