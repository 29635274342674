import React from "react";
import {Card, CardContent, Grid2, ListItemIcon} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import {AttachMoney, Check, Clear} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {RateEnum} from "../../../../enums/RateEnum";
import Button from "@mui/material/Button";

interface IncludedService {
    enabled: boolean,
    name: string,
    warning?: boolean,
    fee?: boolean
}

interface RateDescription {
    title: string,
    rateEnum: RateEnum,
    societyServicesIncluded: IncludedService[],
    eventsServicesIncluded: IncludedService[],
    supportServicesIncluded: IncludedService[],
    monthlyPrice: string,
}

const rates: RateDescription[] = [
    {
        title: 'Starter',
        rateEnum: RateEnum.STARTER,
        societyServicesIncluded: [
            {
                name: 'Accès au service support (Réponse en 7 jours)',
                enabled: true,
            },
            {
                name: 'Invitation aux événements Looty',
                enabled: true,
            },
            {
                name: 'Adresse URL personel',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Description de ton activité',
                enabled: true,
            },
            {
                name: 'Contact: Adresse, réseaux sociaux, ...',
                enabled: true,
            },
            {
                name: 'Gallerie photos (10 images)',
                enabled: true,
                warning: true,
            },
            {
                name: 'Boutique en ligne (5 articles)',
                enabled: true,
                warning: true,
            },
            {
                name: 'Frais de transaction: 5%',
                enabled: true,
                fee: true,
                warning: true,
            },
            {
                name: 'Avis des clients',
                enabled: false,
            },
            {
                name: 'Plateforme de réservation',
                enabled: false,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mise en valeur de ton activité',
                enabled: true,
            },
            {
                name: 'Ton adresse sur notre carte pour augmenter ta visibilité',
                enabled: true,
            },
            {
                name: 'Apparition dans les recherches de notre carnet d\'adresse',
                enabled: true,
            },
            {
                name: 'Ta page apparait dans les meilleures recherches Google',
                enabled: true,
            },
        ],
        monthlyPrice: '9.90 CHF',
    },
    {
        title: 'Pro',
        rateEnum: RateEnum.PRO,
        societyServicesIncluded: [
            {
                name: 'Accès au service support (Réponse en 72 heures)',
                enabled: true,
            },
            {
                name: 'Invitation aux événements Looty',
                enabled: true,
            },
            {
                name: 'Adresse URL personel',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Description de ton activité',
                enabled: true,
            },
            {
                name: 'Contact: Adresse, réseaux sociaux, ...',
                enabled: true,
            },
            {
                name: 'Gallerie photos (illimité)',
                enabled: true,
            },
            {
                name: 'Boutique en ligne (illimité)',
                enabled: true,
            },
            {
                name: 'Frais de transaction: 3.5%',
                enabled: true,
                fee: true,
            },
            {
                name: 'Avis des clients',
                enabled: true,
            },
            {
                name: 'Plateforme de réservation',
                enabled: false,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mise en valeur de ton activité',
                enabled: true,
            },
            {
                name: 'Ton adresse sur notre carte pour augmenter ta visibilité',
                enabled: true,
            },
            {
                name: 'Apparition dans les recherches de notre carnet d\'adresse',
                enabled: true,
            },
            {
                name: 'Ta page apparait dans les meilleures recherches Google',
                enabled: true,
            },
        ],
        monthlyPrice: '29.90CHF',
    },
    {
        title: 'Business',
        rateEnum: RateEnum.SALES,
        societyServicesIncluded: [
            {
                name: 'Accès au service support (Réponse en 72 heures)',
                enabled: true,
            },
            {
                name: 'Invitation aux événements Looty',
                enabled: true,
            },
            {
                name: 'Adresse URL personel',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Description de ton activité',
                enabled: true,
            },
            {
                name: 'Contact: Adresse, réseaux sociaux, ...',
                enabled: true,
            },
            {
                name: 'Gallerie photos (illimité)',
                enabled: true,
            },
            {
                name: 'Boutique en ligne (illimité)',
                enabled: true,
            },
            {
                name: 'Frais de transaction: 2%',
                enabled: true,
                fee: true,
            },
            {
                name: 'Avis des clients',
                enabled: true,
            },
            {
                name: 'Plateforme de réservation',
                enabled: true,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mise en valeur de ton activité',
                enabled: true,
            },
            {
                name: 'Ton adresse sur notre carte pour augmenter ta visibilité',
                enabled: true,
            },
            {
                name: 'Apparition dans les recherches de notre carnet d\'adresse',
                enabled: true,
            },
            {
                name: 'Ta page apparait dans les meilleures recherches Google',
                enabled: true,
            },
        ],
        monthlyPrice: '49.90CHF',
    },
]

const LineOfRates = () => {

    const navigate = useNavigate();

    return (
        <Grid2 container spacing={4}>
            {rates.map((rate: RateDescription) => (
                <Grid2 key={rate.title} size={{xs: 12, sm: 12, md: 12, lg: 4}}>
                    <Card sx={{borderRadius: 5}}>
                        <CardContent>
                            <Typography
                                variant="thirdVariant"
                                textAlign={"left"}
                                sx={{
                                    fontSize: {xs: 26, sm: 28, md: 30, lg: 35}
                                }}
                            >
                                {rate.title}
                            </Typography>
                            <Divider sx={{mt: 1, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    minHeight: {xs: 0, sm: 0}
                                }}
                                >
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        textAlign={"left"}
                                        sx={{mb: 2}}
                                    >
                                        Deviens membre
                                    </Typography>
                                    {rate.societyServicesIncluded.map((serviceIncluded, index) => (
                                        <ListItem key={index} sx={{mt: -2, pl: 0}}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 30,
                                                }}
                                            >
                                                {serviceIncluded.enabled ? (
                                                    <Check color={serviceIncluded.warning ? "warning" : "success"}/>
                                                ) : (
                                                    <Clear color={"error"}/>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={serviceIncluded.name}
                                                          primaryTypographyProps={{
                                                              fontSize: {
                                                                  xs: 16,
                                                                  sm: 16,
                                                                  md: 18
                                                              }
                                                          }}/>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Grid2>
                            <Divider sx={{mt: 0, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    minHeight: {xs: 0, sm: 0}
                                }}
                                >
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        textAlign={"left"}
                                        sx={{mb: 2}}
                                    >
                                        Page web personnalisable
                                    </Typography>
                                    {rate.eventsServicesIncluded.map((serviceIncluded, index) => (
                                        <ListItem key={index} sx={{mt: -2, pl: 0}}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 30,
                                                }}
                                            >


                                                {serviceIncluded.fee ? (
                                                    <AttachMoney
                                                        color={serviceIncluded.warning ? "warning" : "success"}/>
                                                ) : (

                                                    serviceIncluded.enabled ? (
                                                        <Check color={serviceIncluded.warning ? "warning" : "success"}/>
                                                    ) : (
                                                        <Clear color={"error"}/>
                                                    )
                                                )}

                                            </ListItemIcon>
                                            <ListItemText primary={serviceIncluded.name}
                                                          primaryTypographyProps={{
                                                              fontSize: {
                                                                  xs: 16,
                                                                  sm: 16,
                                                                  md: 18
                                                              }
                                                          }}/>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Grid2>
                            <Divider sx={{mt: 0, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    minHeight: {xs: 0, sm: 150}
                                }}
                                >
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        textAlign={"left"}
                                        sx={{mb: 2}}
                                    >
                                        Visibilité sur notre réseau Looty.ch
                                    </Typography>
                                    {rate.supportServicesIncluded.map((serviceIncluded, index) => (
                                        <ListItem key={index} sx={{mt: -2, pl: 0}}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 30,
                                                }}
                                            >
                                                {serviceIncluded.enabled ? (
                                                    <Check color={serviceIncluded.warning ? "warning" : "success"}/>
                                                ) : (
                                                    <Clear color={"error"}/>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={serviceIncluded.name}
                                                          primaryTypographyProps={{
                                                              fontSize: {
                                                                  xs: 16,
                                                                  sm: 16,
                                                                  md: 18
                                                              }
                                                          }}/>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Grid2>
                            <Divider sx={{mt: 0, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={25}
                                        textAlign={"left"}
                                    >
                                        {rate.monthlyPrice}
                                    </Typography>
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        alignContent={'end'}
                                        sx={{
                                            mb: 0.5,
                                            ml: 0.5
                                        }}
                                    >
                                        /mois
                                    </Typography>
                                </Box>
                                <Button
                                    sx={{mt: 2}}
                                    variant={"firstVariant"}
                                    fullWidth
                                    // lowercase for aesthetic reason, uppercase is done in SubscriptionSelection component
                                    onClick={() => navigate(`/register#${rate.rateEnum.toLowerCase()}`)}
                                >
                                    Démarrer
                                </Button>
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default LineOfRates