import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../../../components/customMUIComponents/CustomTextField";
import {CategoryDTO} from "../../../../../context/SocietyProvider";
import useSociety from "../../../../../hooks/useSociety";
import {getListAllCategories} from "../../../articles/articlesUtils";
import Box from "@mui/material/Box";
import styles from "./ModifyMediaModal.module.css";
import {GalleryImageDTO} from "../../SocietyGallery";
import useDataPut from "../../../../../hooks/useDataPut";


interface ModifyMediaModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    galleryImage: GalleryImageDTO,
}

const ModifyMediaModal = ({open, setOpen, galleryImage}: ModifyMediaModalProps) => {
    const {society} = useSociety();

    const [file, setFile] = useState<File>();

    const [mediaCategory, setMediaCategory] = useState<number>(galleryImage.category && galleryImage.category.id ? galleryImage.category.id : -1);

    const allCategories = getListAllCategories(society.galleryImageCategories!);

    const {putData} = useDataPut<GalleryImageDTO>(`/societies/${society.id}/gallery/${galleryImage.id}`, {}, true, false, true);

    const [removedExistingImage, setRemovedExistingImage] = useState<boolean>(false);

    const addMedia = async () => {
        const formData: any = new FormData();

        mediaCategory > 0 && formData.append('categoryId', mediaCategory);

        file && formData.append('newImage', file);

        await putData(undefined, formData);
        window.location.reload();
    };

    const handleRemoveExistingImage = () => {
        setRemovedExistingImage(true);
    }

    useEffect(() => {
        setMediaCategory(galleryImage.category && galleryImage.category.id ? galleryImage.category.id : -1);
        setFile(undefined);
        setRemovedExistingImage(false);
    }, [open]);

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Typography variant={"secondVariant"} fontSize={{xs: 18, sm: 20, md: 22}}>
                        Modifier un média
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{
                    minWidth: '300px'
                }}>
                    <form>

                        {!file && removedExistingImage && (
                            <Box sx={{
                                textAlign: 'center',
                                minWidth: '275px',
                            }}>
                                <input
                                    accept="image/*, android/force-camera-workaround"
                                    capture
                                    style={{display: 'none'}}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={(event) => {
                                        if (event.target.files && event.target.files.length > 0) {
                                            if (event.target.files[0].size > 10485760) {
                                                alert('Fichier trop volumineux. Max 10Mb.')
                                            } else {
                                                setFile(event.target.files[0]);
                                            }
                                        }
                                    }}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="secondVariant" component="span" sx={{
                                        width: '100%'
                                    }}>
                                        Sélectionner une image
                                    </Button>
                                </label>
                            </Box>
                        )}

                        {file && removedExistingImage && (
                            <>
                                <Box
                                    width={'100%'}
                                    textAlign={"center"}
                                >
                                    <img
                                        className={styles.illustration_image}
                                        src={
                                            file && URL.createObjectURL(file)
                                        }
                                        alt={"uploaded file"}
                                    />
                                </Box>
                                <Button
                                    variant={"fifthVariant"}
                                    sx={{width: '100%', mt: 4}}
                                    onClick={() => setFile(undefined)}
                                >
                                    Supprimer l'image
                                </Button>
                            </>
                        )}

                        {!file && !removedExistingImage && (
                            <>
                                <Box
                                    width={'100%'}
                                    textAlign={"center"}
                                >
                                    <img
                                        className={styles.illustration_image}
                                        src={`https://looty.ch:8443/api/v1/societies/${society.id}/gallery/${galleryImage.id}/image`}
                                        alt={"existing illustration"}
                                    />
                                </Box>
                                <Button
                                    variant={"fifthVariant"}
                                    sx={{width: '100%', mt: 4}}
                                    onClick={() => handleRemoveExistingImage()}
                                >
                                    Supprimer l'image
                                </Button>
                            </>
                        )}


                        <CustomTextField
                            variant={"outlined"}
                            value={mediaCategory}
                            label={"Modifier la catégorie"}
                            setField={setMediaCategory}
                            select
                            selectOptions={
                                [
                                    <MenuItem key={'uncategorized'} value={-1}>{'Non catégorisé'}</MenuItem>,
                                    ...allCategories.map((category: CategoryDTO) => {
                                        return (<MenuItem key={category.name}
                                                          value={category.id}>{category.name}</MenuItem>)
                                    })
                                ]
                            }
                            mt={4}
                            textAlign={"left"}
                            required
                        />

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                    <Button onClick={() => addMedia()} disabled={!file && removedExistingImage}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ModifyMediaModal