import React, {useEffect, useState} from "react";
import styles from "./SocietyAdminOrders.module.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import useDataFetch from "../../../hooks/useDataFetch";
import useSociety from "../../../hooks/useSociety";
import Button from "@mui/material/Button";
import useFormatNumber from "../../../hooks/useFormatNumber";
import {Link} from "@mui/material";
import AdminOrdersDetailsModal from "./adminOrderDetailsModal/AdminOrdersDetailsModal";

export interface OrderAdminDTO {
    id: number,
    firstname: string,
    lastname: string,
    city: string,
    zip: string,
    street: string,
    streetNumber: string,
    email: string,
    phoneNumber?: string,
    createdAt: string,
    totalPrice: number,
    orderItems: OrderItemDTO[],
}

export interface OrderItemDTO {
    id: number,
    name: string,
    description: string,
    image: string,
    quantity: number,
    price: number,
}

const defaultOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
};

const SocietyAdminOrders = () => {

    const {society} = useSociety();

    const {formatCentsToPrice} = useFormatNumber();

    const {data: orders} = useDataFetch<OrderAdminDTO[]>(`/societies/${society.id}/orders`, true, true);

    const [openAdminOrdersDetailsModal, setOpenAdminOrdersDetailsModal] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<OrderAdminDTO | undefined>(undefined);

    const handleClickOrder = (order: OrderAdminDTO) => {
        setSelectedOrder(order);
        setOpenAdminOrdersDetailsModal(true);
    }

    const columns: GridColDef<OrderAdminDTO>[] = [
        {
            field: 'id',
            headerName: 'Commande',
            sortable: false,
            renderCell: (params) => (
                <Link
                    sx={{
                        cursor: 'pointer',
                    }}
                    variant={"firstVariant"}
                    onClick={() => handleClickOrder(params.row)}
                >
                    #{params.row.id} {params.row.firstname || ''} {params.row.lastname || ''}
                </Link>),
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            editable: false,
            sortable: true,
            valueGetter: (value, row) => new Date(row.createdAt).toLocaleDateString('fr-FR', defaultOptions),
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Statut',
            editable: false,
            renderCell: (params) => (
                <Button
                    variant={"firstVariant"}
                    onClick={() => alert('hi')}
                >
                    A traiter
                </Button>),
            flex: 0.5,
        },
        {
            field: 'totalPrice',
            headerName: 'Total',
            editable: false,
            sortable: true,
            valueGetter: (value, row) => `${formatCentsToPrice(row.totalPrice)}CHF`,
            flex: 0.5,
        },
    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getResponsiveColumns = () => {
        if (windowWidth < 705) { // Adjust breakpoint as needed
            return columns.filter((column) => column.field !== 'createdAt' && column.field !== 'totalPrice'); // Hide address and city on small screens
        }
        return columns;
    };


    return (
        (orders && orders.length > 0) ? (
            <>

                <Box sx={{
                    width: "100%",
                    backgroundColor: 'secondary.main',
                    alignContent: 'center'
                }}>

                    <Box
                        sx={{
                            backgroundColor: "secondary.main",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div className={styles.content}>
                            <div className={styles.realContent}>
                                <Typography
                                    variant={"secondVariant"}
                                    sx={{
                                        fontSize: {xs: 25, sm: 30}
                                    }}
                                >
                                    Commandes
                                </Typography>

                                <DataGrid
                                    rows={orders}
                                    columns={getResponsiveColumns()}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    disableColumnFilter
                                    disableColumnMenu
                                    disableColumnResize
                                />

                            </div>
                        </div>

                    </Box>
                </Box>
                {selectedOrder && (
                    <AdminOrdersDetailsModal open={openAdminOrdersDetailsModal} setOpen={setOpenAdminOrdersDetailsModal} order={selectedOrder}/>
                )}
            </>
        ) : (
            <></>
        )
    );
}

export default SocietyAdminOrders