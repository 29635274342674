import React, {useState} from "react";
import styles from "./SocietyHomepageDescription.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useSociety from "../../../hooks/useSociety";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import Done from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import CustomTextField from "../../customMUIComponents/CustomTextField";
import useDataPut from "../../../hooks/useDataPut";
import {SocietyDTO} from "../../../context/SocietyProvider";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";

const SocietyHomepageDescription = () => {

    const {society} = useSociety();

    const {societyDescriptionSection} = useSectionsRefs();

    const [editSocietyName, setEditSocietyName] = useState<boolean>(false);
    const [newSocietyName, setNewSocietyName] = useState<string>(society.name!);

    const [editSocietyDescription, setEditSocietyDescription] = useState<boolean>(false);
    const [newSocietyDescription, setNewSocietyDescription] = useState<string>(society.name!);

    const {putData} = useDataPut<SocietyDTO>(`/societies/${society.id}`, {}, true, false);

    const handleEditSocietyName = () => {
        setEditSocietyName(!editSocietyName);
        putData(undefined, {
            societyId: society.id,
            societyName: newSocietyName,
            societyDescription: society.description,
            societyTheme: society.theme,
        }).then((data) => {
            window.location.reload();
        })
    }

    const handleEditSocietyDescription = () => {
        setEditSocietyName(!editSocietyDescription);
        putData(undefined, {
            societyId: society.id,
            societyName: society.name,
            societyDescription: newSocietyDescription,
            societyTheme: society.theme,
        }).then((data) => {
            window.location.reload();
        })
    }

    return (
        <Box
            ref={societyDescriptionSection}
            sx={{
                backgroundColor: "primary.main",
                minHeight: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>

                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'center',
                    minWidth: {xs: '250px', sm: '500px', md: '800px'}, //TODO bug when resizing (maybe not in prod ?): https://github.com/mui/material-ui/issues/43718
                }}>
                    {!editSocietyName ? (
                        <Typography
                            sx={{
                                color: '#ffffff',
                                fontSize: {xs: 30, sm: 40},
                                mt: 4,
                                mb: 4,
                            }}
                            variant={"firstVariant"}
                        >
                            {society.name}
                        </Typography>
                    ) : (
                        <CustomTextField
                            value={newSocietyName}
                            label={'Nom de la société'}
                            setField={setNewSocietyName}
                        />
                    )}

                    <Box sx={{
                        alignContent: 'center',
                        display: society.secured ? 'block' : 'none',
                    }}>
                        {!editSocietyName ? (
                            <IconButton
                                aria-label="Edit"
                                variant="secondVariant"
                                onClick={() => {
                                    setNewSocietyName(society.name!)
                                    setEditSocietyName(true)
                                }}
                            >
                                <Edit/>
                            </IconButton>
                        ) : (
                            <>
                                <IconButton
                                    aria-label="Instagram"
                                    variant="secondVariant"
                                    onClick={() => handleEditSocietyName()}
                                >
                                    <Done/>
                                </IconButton>
                                <IconButton
                                    aria-label="Instagram"
                                    variant="secondVariant"
                                    onClick={() => setEditSocietyName(false)}
                                >
                                    <Close/>
                                </IconButton>
                            </>
                        )}
                    </Box>
                </Box>


                {!editSocietyDescription ? (
                    <Typography
                        textAlign={"left"}
                        sx={{
                            color: '#ffffff',
                            fontSize: {xs: 18, sm: 20, md: 24},
                            mt: 4,
                        }}
                        variant={"firstVariant"}
                    >
                        {society.description}
                    </Typography>
                ) : (
                    <CustomTextField
                        value={newSocietyDescription}
                        label={'Description de la société'}
                        setField={setNewSocietyDescription}
                        multiline={true}
                        rows={6}
                    />
                )}

                <Box sx={{
                    alignContent: 'center',
                    display: society.secured ? 'block' : 'none',
                }}>
                    {!editSocietyDescription ? (
                        <IconButton
                            aria-label="Instagram"
                            variant="secondVariant"
                            onClick={() => {
                                setNewSocietyDescription(society.description!)
                                setEditSocietyDescription(true)
                            }}
                        >
                            <Edit/>
                        </IconButton>
                    ) : (
                        <>
                            <IconButton
                                aria-label="Instagram"
                                variant="secondVariant"
                                onClick={() => handleEditSocietyDescription()}
                            >
                                <Done/>
                            </IconButton>
                            <IconButton
                                aria-label="Instagram"
                                variant="secondVariant"
                                onClick={() => setEditSocietyDescription(false)}
                            >
                                <Close/>
                            </IconButton>
                        </>
                    )}
                </Box>
            </div>
        </Box>
    );
}

export default SocietyHomepageDescription