import React from "react";
import {OrderItemDTO} from "../SocietyAdminOrders";
import AdminOrdersDetailsItemImageBlock from "./adminOrdersDetailsItemImageBlock/AdminOrdersDetailsItemImageBlock";
import {Grid2} from "@mui/material";
import Typography from "@mui/material/Typography";
import useFormatNumber from "../../../../hooks/useFormatNumber";

interface AdminOrdersDetailsItemProps {
    orderItem: OrderItemDTO
    orderId: number
}

const AdminOrdersDetailsItem = ({orderItem, orderId}: AdminOrdersDetailsItemProps) => {

    const {formatCentsToPrice} = useFormatNumber();

    return (
        <Grid2 mb={4} container size={12} sx={{borderBottom: '1px solid gray'}} pb={2}>
            <Grid2 container size={12} pt={1} pb={1}>
                <Grid2 container size={2} height={"100%"} sx={{border: '0px solid black'}} pr={"15px"}>
                    <AdminOrdersDetailsItemImageBlock orderItem={orderItem} orderId={orderId}/>
                </Grid2>
                <Grid2 offset={1} container size={{xs: 9, sm: 4}} height={"100%"} pt={"5px"} pr={"15px"}
                       sx={{border: '0px solid black'}}>
                    <Typography variant={"firstVariant"} textAlign={"left"} width={"100%"}>
                        {orderItem.name}
                    </Typography>
                    <Grid2 container display={{xs: 'flex', sm: 'none'}}>
                        <Grid2 size={12}>
                            <Typography variant={"firstVariant"} textAlign={"left"} width={"100%"} mt={2}>
                                {orderItem.quantity}x {formatCentsToPrice(orderItem.price)}CHF
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant={"firstVariant"} textAlign={"left"} width={"100%"} mt={2}>
                                {formatCentsToPrice(orderItem.quantity * orderItem.price)}CHF
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 container size={2} display={{xs: 'none', sm: 'flex'}} height={"100%"} pt={"5px"}
                       sx={{border: '0px solid black'}}>
                    <Typography variant={"firstVariant"} textAlign={"left"} width={"100%"}>
                        {orderItem.quantity}x
                    </Typography>
                </Grid2>
                <Grid2 container size={2} display={{xs: 'none', sm: 'flex'}} height={"100%"} padding={"5px"}
                       sx={{border: '0px solid black'}}>
                    <Typography variant={"firstVariant"} textAlign={"left"}>
                        {formatCentsToPrice(orderItem.price)}CHF
                    </Typography>
                </Grid2>
                <Grid2 container size={1} display={{xs: 'none', sm: 'flex'}} height={"100%"} padding={"5px"}
                       sx={{border: '0px solid black'}}>
                    <Typography sx={{width: '100%'}} variant={"firstVariant"} textAlign={"left"}>
                        {formatCentsToPrice(orderItem.price * orderItem.quantity)}CHF
                    </Typography>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default AdminOrdersDetailsItem