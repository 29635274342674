import React from "react";
import styles from "./About.module.css";
import {useNavigate} from "react-router-dom";
import {Button, Grid2} from "@mui/material";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ABOUT_CARDS_DATA = [
    {
        title: 'Nous t\'écoutons',
        description: 'Nous sommes toujours à l\'écoute de nos membres pour répondre au maximum à leurs besoins.'
    },
    {
        title: 'Notre solution est innovante',
        description: 'Looty propose une solution unique et simple qui te permet de créer une présence en ligne sans les coûts et les complications techniques associés à un site web.'
    },
    {
        title: 'Nous sommes transparents',
        description: 'Nous sommes ouverts et transparents comme pour les packs et frais de transactions entre autres.'
    },
    {
        title: 'Nous sommes humains',
        description: 'Nous sommes comme toi, et désirons par-dessus tout que ton business fonctionne.'
    },
    {
        title: 'La sécurité',
        description: 'La sécurité de tes données ainsi que ta vie privée font parties de nos priorités.'
    },
    {
        title: 'Nous sommes en Suisse',
        description: 'Nos serveurs et nous-mêmes sommes basés en Suisse ce qui permet un fonctionnement optimal de nos services.'
    },
]

const About = () => {

    const {aboutSection} = useSectionsRefs();

    const navigate = useNavigate();

    return (
        <Box
            ref={aboutSection}
            sx={{
                backgroundColor: "tertiary.main",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Typography
                    sx={{
                        fontSize: {xs: 30, sm: 40},
                    }}
                    variant={"secondVariant"}
                >
                    À PROPOS
                </Typography>

                <Typography
                    sx={{
                        fontSize: {xs: 25, sm: 35,},
                    }}
                    variant={"secondVariant"}
                >
                    Nos valeurs et objectifs
                </Typography>

                <Grid2 container spacing={4} sx={{mt: 2, mb: 2, mx: "auto"}}>

                    {ABOUT_CARDS_DATA.map((aboutCard, index) => {
                        return (
                            <Grid2 key={index} size={{xs: 12, sm: 6, md: 6, lg: 4}}>
                                <Typography
                                    variant={"secondVariant"}
                                    fontSize={{xs: '18px', sm: '22px', md: '26px', lg: '26px'}}
                                    textAlign={"left"}
                                >
                                    {aboutCard.title}
                                </Typography>
                                <Typography
                                    variant={"secondVariant"}
                                    fontSize={{xs: '14px', sm: '18px', md: '20px', lg: '20px'}}
                                    textAlign={"left"}
                                >
                                    {aboutCard.description}
                                </Typography>
                            </Grid2>
                        )
                    })}

                </Grid2>

                <Button
                    variant="fourthVariant"
                    onClick={() => navigate('/info')}
                    sx={{
                        mt: 2,
                        fontSize: {xs: 16, sm: 18, md: 20},
                    }}
                >
                    Deviens membre
                </Button>

            </div>
        </Box>
    );
}

export default About