import React, {useState} from "react";
import {Grid2, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./GalleryMediaBox.module.css";
import IconButton from "@mui/material/IconButton";
import {Delete, Star} from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import useSociety from "../../../../../hooks/useSociety";
import {GalleryImageDTO} from "../../SocietyGallery";
import DeleteMediaModal from "../deleteMediaModal/DeleteMediaModal";
import ModifyMediaModal from "../modifyMediaModal/ModifyMediaModal";
import useDataPut from "../../../../../hooks/useDataPut";

interface GalleryMediaBoxProps {
    galleryImageDTO: GalleryImageDTO,
}

const GalleryMediaBox = ({galleryImageDTO}: GalleryMediaBoxProps) => {

    const [openModifyModal, setOpenModifyModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const [isStar, setIsStar] = useState<boolean>(galleryImageDTO.star);

    const {society} = useSociety();

    const {putData} = useDataPut<GalleryImageDTO>(`/societies/${society.id}/gallery/${galleryImageDTO.id}/star`, {}, true, false, false);

    const handleSetStar = async () => {
        setIsStar(!isStar);
        await putData(undefined, {});
    }

    return (
        <>
            <Grid2 container key={galleryImageDTO.id} size={{xs: 12, sm: 6, md: 3}}>
                <Box
                    position={"relative"}
                    display="flex"
                    justifyContent="center"
                    alignItems={"center"}
                    overflow="hidden"
                    maxHeight={{xs: '450px', sm: '300px', md: '250px'}}
                    margin={'0 auto'}
                    sx={{
                        width: '100%',
                        mb: 2,
                        alignItems: 'center',
                        alignContent: 'center',
                        backgroundColor: 'white',
                    }}
                >
                    <img
                        className={styles.galleryImage}
                        src={`https://looty.ch:8443/api/v1/societies/${society.id}/gallery/${galleryImageDTO.id}/image`}
                        alt="gallery item"/>

                    {society.secured && (
                        <Box sx={{
                            position: 'absolute',
                            float: 'left',
                            right: '0px',
                            bottom: '0px',
                            zIndex: 1000,
                            border: '1px solid black',
                            width: '100%',
                            background: 'rgba(0, 0, 0, 0.5)',
                            textAlign: 'right'
                        }}>
                            <Tooltip title="Afficher sur la page d'accueil (2 minimum)">
                                <IconButton
                                    aria-label="Star"
                                    variant="thirdVariant"
                                    onClick={handleSetStar}
                                    sx={{
                                        mr: 0,
                                    }}
                                >
                                    <Star
                                        sx={{
                                            fontSize: {xs: '20px', sm: '20pxd'},
                                            padding: '0px',
                                            color: isStar ? 'yellow' : 'white',
                                        }}
                                    />

                                </IconButton>
                            </Tooltip>
                            <IconButton
                                aria-label="Edit"
                                variant="thirdVariant"
                                onClick={() => setOpenModifyModal(true)}
                                sx={{
                                    mr: 0,
                                }}
                            >
                                <Edit
                                    sx={{
                                        fontSize: {xs: '20px', sm: '20pxd'},
                                        padding: '0px',
                                        color: 'white',
                                    }}
                                />

                            </IconButton>
                            <IconButton
                                aria-label="Remove"
                                variant="thirdVariant"
                                onClick={() => setOpenDeleteModal(true)}
                                sx={{
                                    mr: 0,
                                }}
                            >
                                <Delete
                                    sx={{
                                        fontSize: {xs: '20px', sm: '20pxd'},
                                        padding: '0px',
                                        color: 'white',
                                    }}
                                />

                            </IconButton>
                            <DeleteMediaModal open={openDeleteModal} setOpen={setOpenDeleteModal}
                                              galleryImage={galleryImageDTO}/>
                        </Box>
                    )}

                </Box>
            </Grid2>
            <ModifyMediaModal open={openModifyModal} setOpen={setOpenModifyModal} galleryImage={galleryImageDTO}/>
        </>
    );
}

export default GalleryMediaBox