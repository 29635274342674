import React, {useEffect, useState} from "react";
import styles from "./SocietyHomepageGallery.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Grid2} from "@mui/material";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import {useNavigate} from "react-router-dom";
import EmblaCarousel from "./emblaCarousel/EmblaCarousel";
import useSociety from "../../../hooks/useSociety";
import useDataFetch from "../../../hooks/useDataFetch";
import {GalleryImageDTO} from "../../../pages/society/gallery/SocietyGallery";

const SocietyHomepageGallery = () => {

    const {articlesSection} = useSectionsRefs();

    const navigate = useNavigate();

    const {society} = useSociety();

    const {data: galleryImages} = useDataFetch<GalleryImageDTO[]>(`/societies/${society.id}/gallery`, false, true);
    const [urls, setUrls] = useState<string[]>([]);

    const [atLeastTwoStars, setAtLeastTwoStars] = useState<boolean>(true);

    useEffect(() => {
        if (galleryImages) {
            const starGalleryImages: GalleryImageDTO[] = galleryImages.filter((galleryImage: GalleryImageDTO) => galleryImage.star)
            setAtLeastTwoStars(starGalleryImages.length > 1);

            const galleryImagesToDisplay: GalleryImageDTO[] = starGalleryImages.length > 0 ? starGalleryImages : galleryImages;

            const newUrls: string[] = galleryImagesToDisplay.map((galleryImage: GalleryImageDTO) => `https://looty.ch:8443/api/v1/societies/${society.id}/gallery/${galleryImage.id}/image`)
            setUrls(newUrls);
        }
    }, [galleryImages]);

    return (

        society.secured || (galleryImages && galleryImages.length >= 1) ? (
            <Box
                ref={articlesSection}
                sx={{
                    backgroundColor: "senary.main",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <Typography
                        sx={{
                            color: '#ffffff',
                            fontSize: {xs: 30, sm: 40},
                        }}
                        variant={"firstVariant"}
                    >
                        Gallerie
                    </Typography>

                    {(society.secured && (galleryImages && galleryImages.length < 1)) && (
                        <Typography
                            variant={"fourthVariant"}
                            fontSize={{xs: 20, sm: 25}}
                        >
                        Vous seul voyez cette section tant que vous n'avez pas ajouté au moins un média.
                    </Typography>)}

                    <Box mt={4}>
                        {
                            urls && urls.length > 0 && (
                                <EmblaCarousel options={{loop: true}} areNeighboursVisible={urls.length > 2} urls={urls} maxSlides={atLeastTwoStars ? urls.length : 5}/>
                            )
                        }
                    </Box>

                    <Grid2 container>
                        <Grid2 size={12}>
                            <Button
                                sx={{
                                    mt: 6,
                                    fontSize: {xs: 16, sm: 18, md: 20},
                                }}
                                variant="fourthVariant"
                                onClick={() => navigate('/gallery')}
                            >
                                Voir tous les médias
                            </Button>
                        </Grid2>
                    </Grid2>

                </div>
            </Box>
        ) : (
            <div></div>
        )
    );
}

export default SocietyHomepageGallery