import React from "react";
import EmblaCarousel
    from "../../../../../components/societyHomepage/societyHomepageGallery/emblaCarousel/EmblaCarousel";
import useSociety from "../../../../../hooks/useSociety";
import {
    ProductDTO
} from "../../../../../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";

interface ArticleImagesSliderProps {
    product: ProductDTO
}

const ArticleImagesSlider = ({product}: ArticleImagesSliderProps) => {

    const {society} = useSociety();

    const urls: string[] = product.productImages.map((productImage) => {
        return `https://looty.ch:8443/api/v1/societies/${society.id}/products/${product.id}/images/${productImage.id}`
    })


    return (

        urls && urls.length > 0 ? (
            <EmblaCarousel options={{loop: true}} areNeighboursVisible={false} urls={urls} maxSlides={5}/>
        ) : (
            <img src={'/missing-image.png'}/>
        )
    );
}

export default ArticleImagesSlider