import React, {ComponentPropsWithRef, useCallback, useEffect, useState} from 'react'
import {EmblaCarouselType} from 'embla-carousel'
import IconButton from "@mui/material/IconButton";
import {ChevronRightRounded, RadioButtonUnchecked} from "@mui/icons-material";

type UseDotButtonType = {
    selectedIndex: number
    scrollSnaps: number[]
    onDotButtonClick: (index: number) => void
}

export const useDotButton = (
    emblaApi: EmblaCarouselType | undefined,
    onButtonClick?: (emblaApi: EmblaCarouselType) => void
): UseDotButtonType => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

    const onDotButtonClick = useCallback(
        (index: number) => {
            if (!emblaApi) return
            emblaApi.scrollTo(index)
            if (onButtonClick) onButtonClick(emblaApi)
        },
        [emblaApi, onButtonClick]
    )

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)

        emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
    }, [emblaApi, onInit, onSelect])

    return {
        selectedIndex,
        scrollSnaps,
        onDotButtonClick
    }
}

type PropType = ComponentPropsWithRef<'button'> & {
    isActive: boolean;
}

export const DotButton: React.FC<PropType> = (props) => {
    const { children, isActive, ...restProps } = props

    return (
        <IconButton
            aria-label="Instagram"
            variant="firstVariant"
            sx={{
                height: isActive ? 20 : 25,
                width: isActive ? 20 : 25,
                backgroundColor: isActive ? "primary.main" : 'transparent',
            }}
            onClick={restProps.onClick}
        >
            <RadioButtonUnchecked />
        </IconButton>
    )
}
