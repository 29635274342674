import React, {useEffect, useState} from "react";
import {useSectionsRefs} from "../../../components/mainWrapper/MainWrapper";
import styles from "./SocietyArticles.module.css"
import Box from "@mui/material/Box";
import {
    ProductDTO
} from "../../../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useDataFetch from "../../../hooks/useDataFetch";
import useSociety from "../../../hooks/useSociety";
import CartOverlay from "../../../components/cartOverlay/CartOverlay";
import {useCartOverlay} from "../../../hooks/useCart";
import Button from "@mui/material/Button";
import AddArticleModal from "../../../components/societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";
import {useParams} from "react-router-dom";
import {getPathToSubcategoryEntry} from './articlesUtils'
import SocietyArticlesList from "./societyArticlesList/SocietyArticlesList";
import CategoriesNavigator from "../../../components/categoriesNavigator/CategoriesNavigator";
import {CategoryDTO} from "../../../context/SocietyProvider";

const SocietyArticles = () => {

    const {society} = useSociety();

    const {categoryId} = useParams();
    const categoryIdNumber = categoryId ? Number(categoryId) : 0;

    const {isCartOpen, closeCart} = useCartOverlay();

    const [openAddArticleModal, setOpenAddArticleModal] = useState<boolean>(false);

    const {setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    const {data: articles} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, true, true);

    useEffect(() => {
        setNavItems([]);
        setDisplayRegisterSocietyButton(false);
    }, [setDisplayRegisterSocietyButton, setNavItems]);

    let categoryPath: CategoryDTO[] = [];

    if (categoryIdNumber !== 0) {
        categoryPath = getPathToSubcategoryEntry(society.categories!, categoryIdNumber) || [];
    }

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'secondary.main',
        }}>

            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>

                        <CategoriesNavigator parentCategories={society.categories!} categoryPath={categoryPath} navigationPath={'/articles/categories'} rootTitle={"Tous les articles"} rootTitleNavigate={'/articles'} isArticleCategory={true}/>

                        {articles && (
                            <SocietyArticlesList categoryId={categoryIdNumber} articles={articles}/>
                        )}

                    </div>
                </div>

            </Box>

            {society.secured && (
                <Button
                    sx={{
                        mt: 0,
                        mb: 4,
                    }}
                    variant={"fourthVariant"}
                    onClick={() => setOpenAddArticleModal(true)}
                >
                    Ajouter un article
                </Button>
            )}

            <CartOverlay isCartOpen={isCartOpen} closeCart={closeCart}/>
            <AddArticleModal open={openAddArticleModal} setOpen={setOpenAddArticleModal}/>
        </Box>
    );
}

export default SocietyArticles