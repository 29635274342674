import React from "react";
import {
    ProductDTO
} from "../../../../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import {Grid2} from "@mui/material";
import ArticleCard from "../../../../components/articleCard/ArticleCard";
import {mapCategoriesPathToIds} from "../articlesUtils";
import useSociety from "../../../../hooks/useSociety";
import Typography from "@mui/material/Typography";

interface SocietyArticlesListProps {
    categoryId: number;
    articles: ProductDTO[];
}

const SocietyArticlesList = ({categoryId, articles}: SocietyArticlesListProps) => {

    const {society} = useSociety();

    const filteredArticles = articles.filter((article: ProductDTO) => {
        if (categoryId === 0) {
            return true;
        }
        return article.category?.id && mapCategoriesPathToIds(society.categories!, article.category.id)?.includes(categoryId)
    });

    return (
        filteredArticles && filteredArticles.length > 0 ? (
            <Grid2 container spacing={2} mt={6}>
                {
                    filteredArticles.map((article: ProductDTO) => (
                        <Grid2
                            key={article.id}
                            size={{xs: 12, sm: 6, md: 6, lg: 3}}
                        >
                            <ArticleCard key={article.id} article={article}/>
                        </Grid2>
                    ))}
            </Grid2>
        ) : (
            <Typography
                sx={{
                    color: '#ffffff',
                    fontSize: {xs: 18, sm: 20, md: 24},
                    mt: 6,
                    textAlign: "center",
                }}
                variant={"firstVariant"}
            >
                Aucun article n'est actuellement disponible dans cette catégorie.
            </Typography>
        )

    );
}

export default SocietyArticlesList