import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {Grid2} from "@mui/material";
import Button from "@mui/material/Button";
import {CategoryDTO} from "../../context/SocietyProvider";
import CategoryPath from "../../pages/society/articles/categoryPath/CategoryPath";
import {useNavigate} from "react-router-dom";
import useSociety from "../../hooks/useSociety";
import SocietyAdminCategoriesAddModal
    from "../societyAdmin/societyAdminCategories/societyAdminCategoriesAddModal/SocietyAdminCategoriesAddModal";
import IconButton from "@mui/material/IconButton";
import {DeleteOutline, EditOutlined} from "@mui/icons-material";
import SocietyAdminCategoriesDeletionModal
    from "../societyAdmin/societyAdminCategories/societyAdminCategoriesDeletetionModal/SocietyAdminCategoriesDeletionModal";
import SocietyAdminCategoriesEditModal
    from "../societyAdmin/societyAdminCategories/societyAdminCategoriesEditModal/SocietyAdminCategoriesEditModal";

interface CategoriesNavigatorProps {
    parentCategories: CategoryDTO[]
    categoryPath: CategoryDTO[]
    noItems?: boolean
    navigationPath: string
    rootTitle: string
    rootTitleNavigate: string,
    isArticleCategory: boolean, // else gallery media category
}

const CategoriesNavigator = ({
                                 categoryPath,
                                 parentCategories,
                                 noItems = false,
                                 navigationPath,
                                 rootTitle,
                                 rootTitleNavigate,
                                 isArticleCategory
                             }: CategoriesNavigatorProps) => {

    const {society} = useSociety();

    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    const [categoryToEditOrDelete, setCategoryToEditOrDelete] = useState<CategoryDTO>();

    const [parentCategoryToAddChild, setParentCategoryToAddChild] = useState<CategoryDTO>();

    const navigate = useNavigate();

    const currentCategories: CategoryDTO[] = categoryPath.length > 0 ? categoryPath[categoryPath.length - 1].subcategories : parentCategories;

    const handleEditCategory = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, category: CategoryDTO) => {
        e.stopPropagation();
        setEditOpen(true);
        setCategoryToEditOrDelete(category);
    }

    const handleDeleteCategory = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, category: CategoryDTO) => {
        e.stopPropagation();
        setDeleteOpen(true);
        setCategoryToEditOrDelete(category);
    }

    return (
        <>

            <CategoryPath categoryPath={categoryPath} navigationPath={navigationPath} rootTitle={rootTitle}
                          rootTitleNavigate={rootTitleNavigate}/>

            {noItems && (
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 22, sm: 26},
                    }}
                    variant={"firstVariant"}
                >
                    Aucun article n'est disponible actuellement. Revenez plus tard !
                </Typography>
            )}

            {(currentCategories.length > 0 || society.secured) && (
                <Grid2 container spacing={2} mt={4}>
                    {currentCategories.map((category: CategoryDTO) => (
                        <Grid2
                            key={category.id}
                            size={{xs: 6, sm: 6, md: 4}}
                            sx={{
                                backgroundColor: '#ffffff',
                                minHeight: '50px',
                                alignContent: 'center',
                                borderRadius: '8px',
                            }}
                        >
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => navigate(`${navigationPath}/${category.id}`)}
                            >
                                <Grid2 container sx={{width: '100%'}}>
                                    <Grid2 size={12} sx={{alignContent: 'center'}}>
                                        <Typography
                                            variant={"thirdVariant"}
                                            fontSize={{xs: '16x', sm: '18px', md: '18px'}}
                                        >
                                            {category.name}
                                        </Typography>
                                    </Grid2>

                                    {society.secured && (
                                        <>
                                            <Grid2 size={12} sx={{alignContent: 'center'}}>
                                                <IconButton
                                                    aria-label="Edit"
                                                    variant="thirdVariant"
                                                    onClick={(e) => handleEditCategory(e, category)}
                                                >
                                                    <EditOutlined
                                                        sx={{
                                                            fontSize: {xs: '20px', sm: '25px'},
                                                            padding: '0px',
                                                        }}
                                                    />

                                                </IconButton>
                                                <IconButton
                                                    aria-label="Remove"
                                                    variant="thirdVariant"
                                                    onClick={(e) => handleDeleteCategory(e, category)}
                                                >
                                                    <DeleteOutline
                                                        sx={{
                                                            fontSize: {xs: '20px', sm: '25px'},
                                                            padding: '0px',
                                                        }}
                                                    />

                                                </IconButton>
                                            </Grid2>
                                        </>
                                    )}
                                </Grid2>
                            </Button>
                        </Grid2>
                    ))}

                    {society.secured && (
                        <>
                            <Grid2
                                size={{xs: 6, sm: 6, md: 4}}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    minHeight: '50px',
                                    alignContent: 'center',
                                    borderRadius: '8px',
                                }}
                            >
                                <Button
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => setAddOpen(true)}
                                >
                                    <Typography
                                        variant={"fourthVariant"}
                                        fontSize={{xs: '16x', sm: '18px', md: '18px'}}
                                    >
                                        {"Ajouter une catégorie"}
                                    </Typography>
                                </Button>
                            </Grid2>
                            <SocietyAdminCategoriesAddModal open={addOpen} setOpen={setAddOpen}
                                                            parentCategory={categoryPath && categoryPath.length > 0 ? categoryPath[categoryPath.length - 1] : undefined}
                                                            setParentCategory={setParentCategoryToAddChild} isArticleCategory={isArticleCategory}/>
                            {categoryToEditOrDelete && (
                                <SocietyAdminCategoriesDeletionModal open={deleteOpen} setOpen={setDeleteOpen}
                                                                     category={categoryToEditOrDelete} isArticleCategory={isArticleCategory}/>
                            )}
                            {categoryToEditOrDelete && (
                                <SocietyAdminCategoriesEditModal open={editOpen} setOpen={setEditOpen}
                                                                 category={categoryToEditOrDelete}
                                                                 parentCategory={categoryPath && categoryPath.length > 0 ? categoryPath[categoryPath.length - 1] : undefined}
                                                                 setParentCategory={setParentCategoryToAddChild} isArticleCategory={isArticleCategory}/>
                            )}
                        </>
                    )}
                </Grid2>
            )}
        </>
    );
}

export default CategoriesNavigator