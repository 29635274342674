import React from "react";
import {Navigate, useParams} from "react-router-dom";
import ArticleDetails from "./articleDetails/ArticleDetails";

const ArticleDetailsWrapper = () => {

    const {id} = useParams();

    return (
        !isNaN(Number(id)) ? <ArticleDetails/> : <Navigate to="/articles" replace />
    );
}

export default ArticleDetailsWrapper